//@ts-ignore
import highlighter from "./highlighter-solid.svg";
/**
 * 
 * @param {{
 *   enableDrawingToggle: boolean,
 *   hideDrawingLabel: string,
 *   readOnly: boolean,
 *   height: number,
 *   width: number
 * }} data 
 */
export function renderEditor(data){ 
  return `
  <div class="response-collector drawing-editor ${data.readOnly ? 'readonly' : ''}">
    <div class="drawing-toolbar">
     ${data.readOnly ? '' : renderToolbar()}
    </div>

    <div class="save-actions" style="display: none;">
      <button class="save-actions-button ${data.readOnly ? 'save-actions-annotate' : 'save-actions-done'}" type="button" tabindex="-1">
      ${renderSaveAction(data.readOnly)}
      </button>
    </div>
    <div class="toggle-action-container">
      <button class="toggle-drawing-action" style="${data.enableDrawingToggle ? 'display: inline-block;' : 'display: none;'}" type="button" tabindex="-1"> ${data.hideDrawingLabel}</button>
    </div>
    <div class="drawing-area" style="position:relative;background:white;width:${data.width}px;">
      <img
        class="drawing-background"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII="
        height="${data.height}"
        width="${data.width}">
      <img class="readonly-layer"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII="
        style="position: absolute; top:0; left:0; display: none"
        height="${data.height}"
        width="${data.width}">
      <canvas
        style="position: absolute; top:0; left:0;"
        height="${data.height}"
        width="${data.width}">
      </canvas>
    </div>
  </div>
`
}

export function renderToolbar() {
  return `
  <button class="drawing-draw-tool active" type="button" tabindex="-1">Pencil</button>
  <button class="drawing-line-tool" type="button" tabindex="-1">Line</button>
  <button class="drawing-erase-tool" type="button" tabindex="-1">Erase</button>
  <div class="drawing-dropdown">
    <button class="drawingdropbtn" type="button">
      <span class="drawing-current-color rounded-corners-sm" style="background-color: Black;">
      </span>
      <div class="drawing-dropdown-content" style="display: none;">
        <span class="drawing-color" style="background-color: #FFFFFF; box-shadow: 0px 0px 1px 1px #E7E7E8;"></span>
        <span class="drawing-color" style="background-color: #BC81EB;"></span>
        <span class="drawing-color" style="background-color: #55A1FD;"></span>
        <span class="drawing-color" style="background-color: #40CB74;"></span>
        <span class="drawing-color" style="background-color: #F9D652;"></span>
        <span class="drawing-color" style="background-color: #FD756B;"></span>

        <span class="drawing-color" style="background-color: #807F7F;"></span>
        <span class="drawing-color" style="background-color: #633786;"></span>
        <span class="drawing-color" style="background-color: #155BB0;"></span>
        <span class="drawing-color" style="background-color: #028934;"></span>
        <span class="drawing-color" style="background-color: #E4BA1A;"></span>
        <span class="drawing-color" style="background-color: #CE3226;"></span>
        
        <span class="drawing-color" style="background-color: #000000;"></span>
        <span class="drawing-color" style="background-color: #441966;"></span>
        <span class="drawing-color" style="background-color: #0A3262;"></span>
        <span class="drawing-color" style="background-color: #03481C;"></span>
        <span class="drawing-color" style="background-color: #A17004;"></span>
        <span class="drawing-color" style="background-color: #91160D;"></span>
      </div>
    </button>
  </div>

  <button class="drawing-size-control point-1" data-value="1" type="button" tabindex="-1"></button>
  <button class="drawing-size-control active point-2" data-value="2" type="button" tabindex="-1"></button>
  <button class="drawing-size-control point-3" data-value="3" type="button" tabindex="-1"></button>
  <button class="drawing-size-control point-5" data-value="5" type="button" tabindex="-1"></button>
  <button class="drawing-size-control point-20" data-value="20" type="button" tabindex="-1"></button>

  <button class="drawing-undo-action" type="button" tabindex="-1"></button>
  <button class="drawing-redo-action" type="button" tabindex="-1"></button>`;
}

/**
 * 
 * @param {boolean} readOnly 
 */
export function renderSaveAction(readOnly) {
  if(readOnly) {
    return `
      <img src="${highlighter}">
      Annotate
    `;
  } else {
    return `
      Done
    `;
  }
}
