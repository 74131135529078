import Question from "./question.js"
import FillInTheBlankQuestionComponent from "../components/questions/fill-in-the-blank-question.js"
import FillInTheBlankReport from "../components/reports/fill-in-the-blank-report.jsx"
import fillInTheBlankPreview from "../components/previews/fill-in-the-blank-preview.jsx";

class FillInTheBlankQuestion extends Question {
  /**
   * @param item {import("../shared-types").ItemJSON}
   */
  constructor(item) {
    super(item);
    this.bodyWithSubstitutions = this.substituteBlanks();
  }

  substituteBlanks() {
    let parser = new DOMParser();
    let dom = parser.parseFromString(this.body, "text/html");
    dom.querySelectorAll(".fill-in-the-blank").forEach((span) => {
      let id = span.getAttribute("data-gap-identifier");
      let inputData = this.responseDeclaration.inputs?.find((input) => {
        return String(input.id) == id;
      })
      if (id) {
        let inputMode = "text"

        if (inputData && inputData.config) {
          inputMode = inputData.config.type == "numeric" ? "decimal" : "text";
        }

        let input = `<input data-gap-identifier="${id}" class="fill-in-the-blank" inputmode="${inputMode}" placeholder="${inputData?.config.placeholder || ""}"/>`
        
        span.innerHTML = input;
        span.classList.remove("fill-in-the-blank");
      }
    });
    return dom.body.innerHTML;
  }

  configForInput(inputId) {
    let input = this.responseDeclaration.inputs?.find((x) => {
      return x.id == inputId
    })
    return input?.config
  }

  /**
   * 
   * @param {HTMLElement} elem 
   */
  processPromptMarkupForTts(elem) {
    elem.querySelectorAll(".fill-in-the-blank").forEach(
      /**
       * 
       * @param {HTMLInputElement} input 
       */
      (input) => {
        if(input.placeholder && input.parentElement){
          let start_mark = document.createElement("mark");
          start_mark.setAttribute("value", "highlight_ignore_start");
          input.parentElement.insertBefore(start_mark, input);

          let s = document.createElement("s");
          s.textContent = input.placeholder;
          input.parentElement.insertBefore(s, input);

          let end_mark = document.createElement("mark");
          end_mark.setAttribute("value", "highlight_ignore_end");
          input.parentElement.insertBefore(end_mark, input.nextSibling);
        } else {
          let breakElem = document.createElement("break");
          input.replaceWith(breakElem);
        }

        input.remove()
      });
    return elem
  }

  component(mode) {
    switch (mode) {
      case "student-facing-report":
        return FillInTheBlankReport;
      case "preview":
        return fillInTheBlankPreview
      default:
        return FillInTheBlankQuestionComponent;
    }
  }
}

export default FillInTheBlankQuestion
