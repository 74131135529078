import BaseComponent from "../base-component.js";
import { h } from "preact";

export default class MatchingReportComponent extends BaseComponent {
    responseCollector() {
        let choices = this.props.item.responseDeclaration.choices;

        /**
         * @type import("../../shared-types").MatchingResponse
         */
        let matchingResponse = this.props.studentResponse?.matching || {
            matching_matches: []
        };

        /**
         * @type {Object.<string, string>}
         */
        let matchingResponseByMatchId = matchingResponse.matching_matches.reduce((acc, match) => {
            acc[match.match_id] = match.choice_id;
            return acc;
        }, {});

        /**
         * @type {Object.<string, string>}
         */
        let matchingResponseByChoiceId = matchingResponse.matching_matches.reduce((acc, match) => {
            acc[match.choice_id] = match.match_id;
            return acc;
        }, {});


        if (choices) {
            let leftColumn = choices
                .filter((c) => c.column == 0)
                .sort((a, b) => a.ordinal - b.ordinal);
            let rightColumn = choices
                .filter((c) => c.column == 1)
                .sort((a, b) => a.ordinal - b.ordinal);

            let choicesById = leftColumn.reduce((acc, choice) => {
                acc[choice.id] = choice;
                return acc;
            }, {});

            let unusedChoices = leftColumn.filter((choice) => !matchingResponseByChoiceId[choice.id]);

            if (!Array.isArray(this.props.scoringData) && this.props.scoringData !== undefined) {
                throw new Error("Scoring data must be an array");
            }

            /**
             * @type {import("../../shared-types").ScoringDataArrayObject[]}
             */
            let scoringData = this.props.scoringData || [];
            if (!scoringData || !(Array.isArray(scoringData))) { scoringData = [] };

            let scoringDataByChoiceId = scoringData.reduce((acc, sd) => {
                acc[sd.choice_id] = sd;
                return acc;
            }, {});

            return (
                <div class="response-collector">
                    <div className="matching-match-container">
                        {rightColumn.map((choice) => {

                            let matchedChoice = choicesById[matchingResponseByMatchId[choice.id]];

                            let sd = this.props.item.correctResponse.partial_credit ? scoringDataByChoiceId[matchedChoice?.id] : this.buildScoringData(matchedChoice, choice);

                            return (
                                <div className="matching-row">
                                    <div className="matching-target" data-id={choice.id}>
                                        {matchedChoice ? this.renderChoice(matchedChoice, sd) : null}
                                    </div>
                                    <div className="matching-arrows">&harr;</div>
                                    <div
                                        key={choice.id}
                                        id={`matching-match-${choice.id}`}
                                        data-id={choice.id}
                                        className="matching-match"
                                    >
                                        <div
                                            class="matching-match-content"
                                            dangerouslySetInnerHTML={{ __html: choice.body }}
                                        ></div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="matching-choice-container">
                        {unusedChoices?.map((choice) => {
                            return (
                                this.renderChoice(choice)
                            );
                        }) || []}
                    </div>
                </div>
            );
        }
    }

    /**
     * 
     * @param {import('../../shared-types').ItemChoice | undefined} choice 
     * @param {import('../../shared-types').ItemChoice} match 
     * @return {import('../../shared-types').ScoringDataArrayObject}
     */
    buildScoringData(choice, match) {
        let correctResponse = this.props.item.correctResponse;
        if(choice && correctResponse.definition?.find((d) => d.matches[0] == choice.id && d.matches[1] == match.id)) {
            return {
                bubble_id: '',
                choice_id: Number(choice.id),
                points: '0',
                correct: true
            }
        } else {
            return {
                bubble_id: '',
                choice_id: Number(choice?.id || 0),
                points: '0',
                correct: false 
            }
        }
    }

    /**
     * @param {import('../../shared-types').ItemChoice} choice 
     * @param {import("../../shared-types").ScoringDataArrayObject} [scoringData]
     * @returns 
     */
    renderChoice(choice, scoringData) {
        let classList = '';

        if (scoringData && scoringData.correct) {
          classList = 'glyphicon fas fa-check glyphicon-ok text-success';
        }
        else if(scoringData) {
          classList = 'glyphicon fas fa-times glyphicon-remove text-danger';
        }
        return <div
            key={choice.id}
            id={`matching-choice-${choice.id}`}
            data-id={choice.id}
            className="matching-choice"
        >
            <div
                class="matching-choice-content"
                dangerouslySetInnerHTML={{ __html: choice.body }}
            ></div>
            <table>
                <tr>
                    {this.props.hideCorrectAnswers ? null : <td><i class={classList}></i></td>}
                    {this.props.hidePoints || !this.props.item.correctResponse.partial_credit ? null : <td>{scoringData ? <div className="matching-choice-score">{scoringData.points}</div> : null}</td>}
                </tr>
            </table>
            
        </div>

    }

    render() {
        return this.wrapper(this.body(), this.responseCollector());
    }
}