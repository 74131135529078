import BaseComponent from "../base-component.js";
import { h } from "preact";
import interact from "../../interact";

import copy from "fast-copy";

//@ts-ignore
import handle from "../../../assets/four-way-arrow.png";


/**
 * @typedef {import("@interactjs/types").Interactable} Interactable
 * @typedef {import("@interactjs/types").InteractEvent} InteractEvent
 * @typedef {import("@interactjs/types").DragEvent} DragEvent
 */

export default class MatchingQuestionComponent extends BaseComponent {
  constructor(props) {
    super(props);
    /**
     * @type {import("src/shared-types.js").StudentResponse}
     */
    this.studentResponse = {
      matching: {
        matching_matches: copy(this.props.studentResponse?.matching?.matching_matches) || [],
      },
    };
  }

  shouldComponentUpdate() {
    return false;
  }

  responseCollector() {
    let choices = this.props.item.responseDeclaration.choices;

    if (choices) {
      let leftColumn = choices
        .filter((c) => c.column == 0)
        .sort((a, b) => a.ordinal - b.ordinal);
      let rightColumn = choices
        .filter((c) => c.column == 1)
        .sort((a, b) => a.ordinal - b.ordinal);

      return (
        <div class="response-collector">
          <div className="matching-match-container">
            {rightColumn.map((choice) => {
              return (
                <div className="matching-row">
                  <div className="matching-target" data-id={choice.id}></div>
                  <div className="matching-arrows">&harr;</div>
                  <div
                    key={choice.id}
                    id={`matching-match-${choice.id}`}
                    data-id={choice.id}
                    className="matching-match"
                  >
                    <div
                      class="matching-match-content"
                      dangerouslySetInnerHTML={{ __html: choice.body }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="matching-choice-container">
            <button
              class="matching-clear"
              onClick={() => {
                this.clearResponse();
              }}
            >
              Clear Response
            </button>
            {leftColumn?.map((choice) => {
              return (
                <div
                  key={choice.id}
                  id={`matching-choice-${choice.id}`}
                  data-id={choice.id}
                  className="matching-choice"
                >
                  <img src={handle} class="matching-choice-handle" />
                  <div
                    class="matching-choice-content"
                    dangerouslySetInnerHTML={{ __html: choice.body }}
                  ></div>
                </div>
              );
            }) || []}
          </div>
        </div>
      );
    }
  }

  componentDidMount() {
    this.props.didRender();
    var _choiceContainer;
    if (this.base instanceof HTMLElement) {
      _choiceContainer = this.base.querySelector(".matching-choice-container");
      if (_choiceContainer instanceof HTMLElement) {
        var choiceContainer = _choiceContainer;
      } else {
        throw "Failed to find .matching-choice-container";
      }
    } else {
      throw "expected base to be an html element";
    }
    this.base.querySelectorAll(".matching-target").forEach(
      /**
       * @param {HTMLDivElement} matchingTarget
       */
      (matchingTarget) => {
        matchingTarget.innerHTML = "";
        let res = this.studentResponse.matching?.matching_matches.find(
          (m) => {
            return String(m.match_id) == matchingTarget.dataset.id;
          }
        );
        if (res) {
          let elem = choiceContainer.querySelector(
            `#matching-choice-${res.choice_id}`
          );
          if (elem) {
            matchingTarget.appendChild(elem);
          }
        }
      }
    );

    /**
     * @type Interactable[]
     */
    let interactions = [];
    var n = interactions.push(interact(".matching-choice"));
    interact.dynamicDrop(true)
    /**
     * @type HTMLElement | null
     */
    var container = null;
    if(this.props.dragScrollContainer) {
      container = document.querySelector(this.props.dragScrollContainer)
    }

    interactions[n - 1].draggable({
      // enable inertial throwing
      inertia: true,
      // enable autoScroll
      autoScroll: {
        container: container || undefined,
        enabled: true
      },
      max: 1,

      listeners: {
        /**
         * 
         * @param {InteractEvent} event 
         */
        start: function (event) {
          event.target.style.opacity = "0.75";
        },
        // call this function on every dragmove event
        move: dragMoveListener(container || undefined),

        // call this function on every dragend event
        end(event) {
          event.target.style.opacity = "1";
          if (!event.dropzone) {
            event.target.style.transform = "";
            event.target.removeAttribute("data-x");
            event.target.removeAttribute("data-y");
            event.target.removeAttribute("data-scroll-top");
          }
        },
      },
    });

    n = interactions.push(interact(".matching-target"));
    interactions[n - 1].dropzone({
      // only accept elements matching this CSS selector
      accept: ".matching-choice",
      // listen for drop related events:
      ondropactivate: function (event) {
        // add active dropzone feedback
        event.target.classList.add("drop-active");
      },
      ondragenter: function (event) {
        event.target.classList.add("matching-drag-over");
        event.relatedTarget.classList.add("can-drop");
      },
      ondragleave: function (event) {
        event.target.classList.remove("matching-drag-over");
        event.relatedTarget.classList.remove("can-drop");
      },
      /**
       *
       * @param {InteractEvent} event
       */
      ondrop: (event) => {
        if (!(event.relatedTarget instanceof HTMLElement)) {
          return;
        }
        let existingGap = event.relatedTarget.closest(".matching");
        if (existingGap instanceof HTMLElement) {
          this.removeMatch(Number(event.relatedTarget.dataset.id));
        }

        let existingChoiceElem =
          event.target.querySelector(".matching-choice");
        event.target.classList.remove("matching-drag-over");
        event.relatedTarget.classList.remove("can-drop");
        event.target.appendChild(event.relatedTarget);
        event.relatedTarget.style.transform = "";
        event.relatedTarget.removeAttribute("data-x");
        event.relatedTarget.removeAttribute("data-y");
        event.relatedTarget.removeAttribute("data-scroll-top");

        if (existingChoiceElem instanceof HTMLElement) {
          choiceContainer.appendChild(existingChoiceElem);
          this.props.item.attributes.response_declaration.choices
            ?.sort((a, b) => a.ordinal - b.ordinal)
            .forEach((choice) => {
              let choiceElem = choiceContainer.querySelector(
                `#matching-choice-${choice.id}`
              );
              if (choiceElem) {
                choiceContainer.appendChild(choiceElem);
              }
            });

          this.removeMatch(Number(existingChoiceElem.dataset.id));
        }

        this.addMatch(
          Number(event.relatedTarget.dataset.id),
          Number(event.target.dataset.id)
        );
      },
      ondropdeactivate: function (event) {
        event.target.classList.remove("matching-drag-over");
      },
    });

    n = interactions.push(interact(choiceContainer));
    interactions[n - 1].dropzone({
      accept: ".matching-choice",
      ondrop: (event) => {
        event.target.appendChild(event.relatedTarget);
        event.relatedTarget.style.transform = "";
        event.relatedTarget.removeAttribute("data-x");
        event.relatedTarget.removeAttribute("data-y");
        this.props.item.attributes.response_declaration.choices
          ?.sort((a, b) => a.ordinal - b.ordinal)
          .forEach((choice) => {
            let choiceElem = choiceContainer.querySelector(
              `#matching-choice-${choice.id}`
            );
            if (choiceElem) {
              choiceContainer.appendChild(choiceElem);
            }
          });

        this.removeMatch(Number(event.relatedTarget.dataset.id));
      },
    });

    this.interactions = interactions;
  }

  componentWillUnmount() {
    if (this.interactions) {
      this.interactions.forEach((i) => {
        i.unset();
      });
    }
  }

  render() {
    return this.wrapper(this.body(), this.responseCollector());
  }

  /**
   *
   * @param {number} choiceId
   * @param {number} matchId
   */
  addMatch(choiceId, matchId) {
    this.studentResponse = {
      matching: {
        matching_matches: [
          ...(this.studentResponse.matching?.matching_matches
              .filter((match) => {return (match.match_id !== matchId && match.choice_id !== choiceId)}) || []),
          {
            match_id: matchId,
            choice_id: choiceId,
          },
        ],
      },
    };
    this.props.responseDidChange(this.studentResponse);
  }

  /**
   *
   * @param {number} choiceId
   */
  removeMatch(choiceId) {
    if(!this.studentResponse.matching) {
      return;
    }

    this.studentResponse = {
      matching: {
        matching_matches: this.studentResponse.matching.matching_matches.filter(
          (match) => {
            return match.choice_id !== choiceId;
          }
        ),
      },
    };
    this.props.responseDidChange(this.studentResponse);
  }

  clearResponse() {
    if (!this.base || !(this.base instanceof HTMLElement)) {
      return;
    }
    if (
      !this.studentResponse.matching ||
      this.studentResponse.matching.matching_matches.length == 0
    ) {
      return;
    }
    let base = this.base;
    let container = base.querySelector(".matching-choice-container");

    this.props.item.attributes.response_declaration.choices
      ?.sort((a, b) => a.ordinal - b.ordinal)
      .forEach((choice) => {
        let choiceElem = base.querySelector(`#matching-choice-${choice.id}`);
        if (choiceElem) {
          container?.appendChild(choiceElem);
        }
      });

    this.studentResponse = {
      matching: {
        matching_matches: [],
      },
    };

    this.props.responseDidChange(this.studentResponse);
  }
}

/**
 *
 * @param {HTMLElement | undefined} container 
 */
function dragMoveListener(container) {
  /**
   * 
   * @param {InteractEvent} event 
   */
  return function(event){
    var target = event.target;

    var offset = 0;
    if(container) {
      var originalScrollTop = target.getAttribute("data-scroll-top")
      if(!originalScrollTop) {
        originalScrollTop = String(container.scrollTop);
        target.setAttribute("data-scroll-top", originalScrollTop)
      }

      var currentScrollTop = container.scrollTop;
      offset = parseFloat(originalScrollTop) - currentScrollTop;
    }

    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute("data-x") + "") || 0) + event.dx;
    var y = (parseFloat(target.getAttribute("data-y") + "") || 0 ) + event.dy ;

    // translate the element
    target.style.transform = "translate(" + x + "px, " + (y - offset) + "px)";

    // update the posiion attributes
    target.setAttribute("data-x", String(x));
    target.setAttribute("data-y", String(y));
  };
};
