
import { h, RefObject, Fragment, createContext } from "preact";
import { useEffect, useRef, useState, useContext,  } from "preact/hooks";
import interact, { makeNewRestrictedPosition } from "../../interact";
import { xMark, backspace, warning, squareRoot } from "../../icons";
import { ComputeEngine } from "@cortex-js/compute-engine"
import { MathfieldElement, renderMathInElement } from "mathlive";
import Complex from "complex.js";

/**
 * @typedef {Object} Options
 * @property {string} type 
 */

/**
 * @typedef {Options & {questionId: string, close: () => void}} Props 
 */
const computeEngine = buildComputeEngine("radian");

/**
 * @type {import("mathlive").MathfieldElement | null}
 */

let initialMathFieldState = null;

/**
 * @type {import("@cortex-js/compute-engine").BoxedExpression}
 */
let initialAnswerState = computeEngine.parse("");

/**
 * @type {'radian' | 'degree'}
 */
const initialAngleModeState = 'radian'

const allowedKeysBasic = {
    '0': true,
    '1': true,
    '2': true,
    '3': true,
    '4': true,
    '5': true,
    '6': true,
    '7': true,
    '8': true,
    '9': true,
    '.': true,
    '(': true,
    ')': true,
    '+': true,
    '-': true,
    '*': '×',
    '/': '÷',
    '%': true,
    'ArrowRight': true,
    'ArrowLeft': true,
    'ArrowDown': true,
    'ArrowUp': true,
    'Backspace': true,
    'Enter': true
}

const allowedKeysReadonly = {
    'ArrowRight': true,
    'ArrowLeft': true,
    'ArrowDown': true,
    'ArrowUp': true,
}

/**
 * @param {Props} props
 */
export default function (props) {
    /**
     * @type {RefObject.<HTMLDivElement>}
     */
    let floatingWindowRef = useRef(null);

    /**
     * @type {RefObject.<HTMLDivElement>}
     */
    let displayRef = useRef(null);


    let [errorMessage, setErrorMessage] = useState("");

    let [mathfield, setMathfield] = useState(initialMathFieldState);
    let [prevMathField, setPrevMathField] = useState(initialMathFieldState);

    const [answer, setAnswer] = useState(initialAnswerState);
    const [angleMode, setAngleMode] = useState(initialAngleModeState);

    useEffect(() => {
        let position = { x: 0, y: 0 };
        let interactable = interact(".calculator-floating-window").draggable({
            allowFrom: ".calculator-floating-window-header",
            max: 1,
            autoScroll: {
                enabled: true
            },
            listeners: {
                move: (evt) => {
                    position = makeNewRestrictedPosition(evt, position); 
                    if (floatingWindowRef.current) {
                        floatingWindowRef.current.style.transform = `translate(${position.x}px, ${position.y}px)`
                    }
                },
            },
        });
    }, [])

    useEffect(() => {
        if (!displayRef.current) {
            return
        }

        function makeMathField() {
            let elem = new MathfieldElement({
                fontsDirectory: process.env.NODE_ENV == "production" ? 'https://cdn.masterymanager.com/mm-item-player/fonts' : "/assets",
                virtualKeyboardMode: "off",
                computeEngine: computeEngine,
            });

            return elem;
        }

        document.body.style.setProperty("--contains-highlight-backround-color", "rgba(166, 166, 166, 0.26)")

        let newPrevMathfield = makeMathField();
        displayRef.current.querySelector(".calculator-previous-output")?.appendChild(newPrevMathfield);
        setPrevMathField(newPrevMathfield);
        newPrevMathfield.addEventListener("change", () => {debugger})

        let newCurrentMathfield = makeMathField();
        displayRef.current.querySelector(".calculator-current-output")?.appendChild(newCurrentMathfield);

        setMathfield(newCurrentMathfield);
    }, [props.type]);

    useEffect(() => {
        if (!mathfield) return;
        let mf = mathfield;

        if(!prevMathField) return;
        let pmf = prevMathField;
        /**
         * 
         * @param {KeyboardEvent} evt 
         */
        const handleKeyDown = (evt) => {
            if (props.type !== "scientific" && !allowedKeysBasic[evt.key]) {
                evt.preventDefault()
            } else if (typeof allowedKeysBasic[evt.key] == "string") {
                evt.preventDefault();
                mf.insert(allowedKeysBasic[evt.key]);
            }

            if (evt.key == "Enter") {
                evt.preventDefault();
                evaluateAnswer(mf, pmf, setAnswer, answer, setErrorMessage, angleMode);
            }
        }

        mf.addEventListener('keydown', handleKeyDown, {
            capture: true,
        });

        const handleKeyDownReadonly = (evt) => {
            if(!allowedKeysReadonly[evt.key]) evt.preventDefault();
        };

        pmf.addEventListener('keydown', handleKeyDownReadonly, {
            capture: true,
        });

        return function cleanup() {
            mf.removeEventListener('keydown', handleKeyDown, { capture: true })
            pmf.removeEventListener('keydown', handleKeyDownReadonly, { capture: true })
        }

    }, [mathfield, prevMathField,answer, setAnswer, setErrorMessage, angleMode])

    useEffect(() => {
        if (!mathfield) return;
        mathfield.setValue("");
        prevMathField?.setValue("");
        setAnswer(computeEngine.parse(""));
    }, [props.questionId])

    useEffect(() => {
        let timeout = 0;
        if (errorMessage) {
            timeout = window.setTimeout(() => { setErrorMessage("") }, 3000);
        }
        return function cleanup() {
            if (timeout)
                window.clearTimeout(timeout);

        }
    }, [errorMessage])


    const handleButtonClick =
        /**
         * 
         * @param {MouseEvent} evt 
         */
        (evt) => {
            if (!mathfield || !prevMathField) return;
            mathfield.focus();
            let _target = evt.target;
            /**
             * @type {HTMLButtonElement}
             */
            // @ts-ignore
            let target = _target.closest(".calculator-button");

            if (target.dataset.action) {
                if (target.dataset.action === "clear") {
                    mathfield.setValue("");
                    prevMathField?.setValue("");
                    setErrorMessage("");
                    setAnswer(computeEngine.parse(""));
                } else if (target.dataset.action === "eval") {
                    evaluateAnswer(mathfield, prevMathField, setAnswer, answer, setErrorMessage, angleMode);
                } else if (target.dataset.action == "backspace") {
                    mathfield.executeCommand('deleteBackward');
                }
            }

            else if (target.dataset.op) {
                mathfield.insert(target.dataset.op);
            } else if (target.dataset.value) {
                mathfield.insert(target.textContent || "");
            }
        }


    let layout = null;

    let hasAnswer = prevMathField ? prevMathField.getValue().length > 0 : false;
    if (props.type == "basic") {
        layout = <div class="calculator-buttons-basic">
            <BasicLayout answerAvailable={hasAnswer} />
        </div>;
    } else if (props.type == "scientific") {
        layout = <div class="calculator-two-column">
            <div class="calculator-buttons-sci">
                <ScientificLayout angleMode={angleMode} setAngleMode={setAngleMode} />
            </div>
            <div class="calculator-buttons-basic">
                <BasicLayout answerAvailable={hasAnswer} />
            </div>
        </div>;
    } else {
        throw (`Invalid type: ${props.type}`)
    }

    return <div class={`calculator-floating-window floating-window ${props.type}-calculator`} ref={floatingWindowRef}>
        <div class="calculator-floating-window-header floating-window-header drag-handle">
            <div class="calculator-floating-window-header-title floating-window-header-title">Calculator</div>
            <div class="calculator-floating-window-header-exit floating-window-header-exit" dangerouslySetInnerHTML={{ __html: xMark }} onClick={props.close}></div>
        </div>

        <div class="calculator-editor-container">
            <div class="calculator-display" ref={displayRef}>
                <div class="calculator-previous-output"></div>
                <div class="calculator-current-output"></div>
                {errorMessage ? <div class="calculator-error"><span dangerouslySetInnerHTML={{ __html: warning }}></span> {errorMessage}</div> : null}
            </div>

            <div onClick={handleButtonClick}>
                {layout}
            </div>
        </div>
    </div>
}



/**
 * 
 * @param {{answerAvailable: boolean}} props 
 */
function BasicLayout(props) {
    return <>
        <CalculatorButton modifier="clear" action="clear" text="AC"/>
        <CalculatorButton op="(" text="("/>
        <CalculatorButton op=")" text=")"/>
        <button class="calculator-button" data-action="backspace" dangerouslySetInnerHTML={{ __html: backspace }}/>
        <CalculatorButton disabled={!props.answerAvailable} op={`\\mathrm{ans}`} text="ANS"/>
        <CalculatorButton op="\%" text="%"/>
        <CalculatorButton op={`\\sqrt{#0}`} math={`\\sqrt{\\mathrm{x}}`}/>
        <CalculatorButton op="÷" text="÷"/>
        <CalculatorButton value="7" text="7"/>
        <CalculatorButton value="8" text="8"/>
        <CalculatorButton value="9" text="9"/>
        <CalculatorButton op="×" text="×"/>
        <CalculatorButton value="4" text="4"/>
        <CalculatorButton value="5" text="5"/>
        <CalculatorButton value="6" text="6"/>
        <CalculatorButton op="-" text="-"/>
        <CalculatorButton value="1" text="1"/>
        <CalculatorButton value="2" text="2"/>
        <CalculatorButton value="3" text="3"/>
        <CalculatorButton op="+" text="+"/>
        <CalculatorButton value="0" text="0"/>
        <CalculatorButton value="." text="."/>
        <CalculatorButton action="eval" colSpan={2} text="="/>
    </>
}

/** 
 * @param {{
 *  angleMode: 'degree' | 'radian',
 *  setAngleMode: (angleMode: 'degree' | 'radian') => void
 * }} props 
 */
function ScientificLayout(props) {
    let [modifierActive, setModifierActive] = useState(false);

    return <ModifierActiveContext.Provider value={modifierActive}>
        <div class="calculator-toggle-container" data-op="^\placeholder[]{}">
            <button class={`calculator-button ${props.angleMode == 'radian' ? 'selected' : ''}`} onClick={() => props.setAngleMode('radian')}>rad</button>
            <button class={`calculator-button ${props.angleMode == 'degree' ? 'selected' : ''}`} onClick={() => props.setAngleMode('degree')}>deg</button>
        </div>

        <button class={`calculator-button ${modifierActive ? "selected" : ""}`} onClick={(evt) => {
            evt.preventDefault();
            setModifierActive(!modifierActive);
        }}>ALT</button>

        <CalculatorButton op="\sin\left(\placeholder{}\right)" altOp="\arcsin\left(#0\right)" math={`\\mathrm{sin}`} altMath={`\\mathrm{sin}^{-1}`}/>
        <CalculatorButton op="\cos\left(\placeholder{}\right)" altOp="\arccos\left(#0\right)" math={`\\mathrm{cos}`} altMath={`\\mathrm{cos}^{-1}`}/>
        <CalculatorButton op="\tan\left(\placeholder{}\right)" altOp="\arctan\left(#0\right)" math={`\\mathrm{tan}`} altMath={`\\mathrm{tan}^{-1}`}/>
        <CalculatorButton op="\ln\left(\placeholder{}\right)" math={`\\mathrm{ln}`}/>
        <CalculatorButton op="\log\left(\placeholder{}\right)" altOp="10^\placeholder{}" math={`\\mathrm{log}`} altMath={`10^\\mathrm{x}`}/>
        <CalculatorButton op="\operatorname{round}\left(#?\right)" math={"\\mathrm{round}"}/>
        <CalculatorButton op="\pi" math={'\\pi'}/>
        <CalculatorButton op="e" text={"e"}/>
        <CalculatorButton op="!" text={"!"}/>
        <CalculatorButton op="\sqrt[\placeholder[]{}]{#1}" math={`\\sqrt[\\mathrm{n}]{\\mathrm{x}}`}/>
        <CalculatorButton op="\left|\placeholder{}\right|" math={`\\left|\\mathrm{x}\\right|`}/>
        <CalculatorButton op="\frac{\placeholder[]{}}{\placeholder[]{}}" math={`\\frac{\\mathrm{a}}{\\mathrm{b}}`}/>
        <CalculatorButton op="e^\placeholder[]{}" math={`e^\\mathrm{x}`} />
        <CalculatorButton op="#@^{#?}" math={`\\mathrm{x}^\\mathrm{y}`} /> 
        <CalculatorButton op="#@^{2}" math={`\\mathrm{x}^2`} /> 
    </ModifierActiveContext.Provider>
}

const ModifierActiveContext = createContext(false);

/**
 * @param {{math?: string, altMath?: string, op?: string, altOp?: string, action?: string, disabled?: boolean, text?: string, altText?: string, value?: string, modifier?: string, colSpan?: number}} props 
 */
function CalculatorButton(props) {
    let modifierActive = useContext(ModifierActiveContext);
    return <button class={`calculator-button ${props.value ? "numeric" : ""} ${props.modifier}`} style={props.colSpan ? `grid-column: span ${props.colSpan}` : undefined}
        data-value={props.value}
        data-op={modifierActive ? props.altOp || props.op : props.op}
        data-action={props.action}
        disabled={props.disabled}>
    {props.math ? <StaticMath math={modifierActive ? props.altMath || props.math : props.math}/> : modifierActive ? props.altText || props.text : props.text}
    </button>
}


/**
 * 
 * @param {{math: string}} props 
 */
function StaticMath(props) {
    /**
     * @type {RefObject.<HTMLDivElement>}
     */
    let mathContainerRef = useRef(null);
    useEffect(() => {
        if (!mathContainerRef.current) return;
        renderMathInElement(mathContainerRef.current, {
            fontsDirectory: process.env.NODE_ENV == "production" ? 'https://cdn.masterymanager.com/mm-item-player/fonts' : "/assets",
        });

        return () => {
            if (!mathContainerRef.current) return;
            let elem = mathContainerRef.current.querySelector(":not(script)")
            if(elem) elem.remove()
        }
    }, [props.math])

    return <div ref={mathContainerRef}>
        <script type="math/tex">{props.math}</script>
    </div>
}

/**
 * 
 * @param {MathfieldElement} mathfield 
 * @param {MathfieldElement} prevMathField 
 * @param {import('@cortex-js/compute-engine').BoxedExpression} answer 
 * @param {(s: import("@cortex-js/compute-engine").BoxedExpression) => void} setAnswer 
 * @param {(s: string) => void} setErrorMessage 
 * @param {'degree' | 'radian'} angleMode
 */
const evaluateAnswer = (mathfield, prevMathField, setAnswer, answer, setErrorMessage, angleMode) => {
    if (!mathfield) return;
    try {
        let val = mathfield.getValue();
        let parsed = computeEngine.parse(val);
        if (!parsed.isValid) {
            setErrorMessage("Invalid input")
            return
        }

        let subbed = parsed.subs({
            ans: answer
        })

        let rewritten = subbed;
        if (angleMode == "degree") {
            rewritten = computeEngine.box(rewriteTrigFns(subbed.json));
        }

        let evalulated = rewritten.evaluate();

        if (!evalulated.isValid) {
            setErrorMessage("Invalid input")
            return
        }

        /**
         * @type string | null
         */
        let evalVal = null;
        /**
         * @type string | null
         */
        let ans = null
        let n = evalulated.N();
        let numericValue = n.numericValue;
        if(numericValue == null) {
            setErrorMessage("Invalid input")
            return;
        } else if(typeof numericValue == "number") {
            if(Number.isInteger(numericValue)) {
                evalVal = numericValue.toString();
                ans = evalVal;
            } else {
                evalVal = formatNumber(numericValue);
                ans = n.latex;
            }
        } else if(numericValue instanceof Array) {
            let nom = typeof numericValue[0] == "number" ? numericValue[0] : numericValue[0].toNumber();
            let dom = typeof numericValue[1] == "number" ? numericValue[1] : numericValue[1].toNumber();
            let div = nom / dom;

            evalVal = Number.isInteger(div) ? div.toString() : formatNumber(div); 
            ans = n.latex
        } else if(numericValue instanceof Complex) {
            let numVal = numericValue.valueOf()
            if(!numVal) {
                evalVal = n.latex
            } else {
                evalVal = Number.isInteger(numVal) ? numVal.toString() : formatNumber(numVal); 
            }
        } else {
            let numVal = numericValue.toNumber();
            evalVal = Number.isInteger(numVal) ? numVal.toString() : formatNumber(numVal) 
        }

        mathfield.setValue("");
        setAnswer(n);
        prevMathField?.setValue(val + "=" + evalVal);
        let waitAndScroll = () => {
            // @ts-ignore
            if(prevMathField._mathfield.dirty) {requestAnimationFrame(waitAndScroll); return;}
            // @ts-ignore
            prevMathField._mathfield.field.scrollLeft = prevMathField._mathfield.field.scrollWidth
        };
        requestAnimationFrame(waitAndScroll);
    } catch (e) {
        console.error(e);
        setErrorMessage("Invalid input");
    }
};

/**
 * @param {'radian' | 'degree'} mode 
 */
function buildComputeEngine(mode) {
    const computeEngine = new ComputeEngine({
        latexDictionary: defaultDictionary(),
    });

    computeEngine.latexOptions = { groupSeparator: "", avoidExponentsInRange: [-8, 20]};
    return computeEngine;
}

/**
 * @returns {import('@cortex-js/compute-engine').LatexDictionaryEntry[]}
 */
function defaultDictionary() {
    return [...ComputeEngine.getLatexDictionary().filter(x => (x.name !== 'Decrement')), {
        name: "LogFn",
        trigger: "\\log",
        /**
         * 
         * @param {import('@cortex-js/compute-engine').Parser} parser 
         * @returns {import("@cortex-js/compute-engine/dist/types/math-json").Expression | null}
         */
        parse: (parser) => {
            const arg = parser.matchArguments('implicit');
            if (arg == null) return null;
            return ['Log', ...arg, 10];
        }
    }]
}

const trigFns = [
    "Sin",
    "Cos",
    "Tan",
]

const inverseTrigFns = [
    "Arccos",
    "Arcsin",
    "Arctan"
]

/**
 * 
 * @param {import('@cortex-js/compute-engine/dist/types/math-json').Expression} exp 
 * @returns {import('@cortex-js/compute-engine/dist/types/math-json').Expression} 
 */
function rewriteTrigFns(exp) {
    if (Array.isArray(exp)) {
        let head = exp[0];
        if (typeof (head) == 'string' || head["fn"]) {
            let fnName = head["fn"] ? head["fn"] : head;
            if (trigFns.includes(fnName)) {
                return [head, ["Multiply", ["Rational", "Pi", 180], rewriteTrigFns(exp[1])]]
            } else if (inverseTrigFns.includes(fnName)) {
                return ["Multiply", ["Rational", 180, "Pi"], [head, rewriteTrigFns(exp[1])]];
            } else {
                return [head, ...exp.slice(1, exp.length).map((subexp) => rewriteTrigFns(subexp))]
            }
        }
    }

    return exp
}

/**
 * 
 * @param {number} number 
 */
function formatNumber(number) {
    if(number < 0.000001 && number > 0 || (number < 0 && number > -0.000001)) {
        return number.toExponential().replace(/e\+?/, ' × 10^{') + "}";
    }
    return parseFloat(number.toFixed(8)).toString()
}