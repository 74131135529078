
const KNOWN_BLOCK_ELEMENTS = {
  "P": true,
  "H1": true,
  "H2": true,
  "H3": true,
  "H4": true,
  "H5": true,
  "H6": true
}

/** @typedef {Object} Highlight
 * @property {number} id
 * @property {Range} range
 * @property {string} color
 */
export default class HighlightEditor {
  /**
   *
   * @param {{
   *  onHighlightSelection: function(number): void | undefined,
   *  onDeselect?: function(number): void,
   *  highlightClass?: string,
   *  deselectOnOutsideClick?: boolean,
   *  highlightFilter?: function(Node): boolean,
   * }} params
   */
  constructor(params) {
    /**
     * @type HTMLElement
     */
    this.element = document.createElement("div");
    this.onHighlightSelection = params.onHighlightSelection || (() => {});
    this.onDeselect = params.onDeselect || (() => {});
    this.highlightClass = params.highlightClass || "comment-highlight";
    this.deselectOnOutsideClick = params.deselectOnOutsideClick || false;
    this.handleMouseUp = this._handleMouseUp.bind(this);
    this.highlightFilter = params.highlightFilter || (() => false)
  }

  /**
   *
   * @param {HTMLElement} element
   */
  setRootElement(element) {
    this.element = element;
    this.setupMouseUpEvent();
  }

  /**
   * @param {Highlight} highlight
   */
  renderHighlight(highlight, select=true) {
    let range = highlight.range;
    if(this.isEquationRange(range)) {
      this.highlightRange(range, highlight);
      return;
    }
    let highlightRanges = this.breakupRange(range);
    highlightRanges.forEach((r) => {console.log(r); this.highlightRange(r, highlight, select) });
  }

  /**
   * @param {Range} range
   * @returns Range[]
   */
  breakupRange(range) {
    let rangesToHighlight = [];
    let common = range.commonAncestorContainer;

    let treeWalker = document.createTreeWalker(common);

    var startNode;
    if(range.startContainer.nodeType !== Node.TEXT_NODE) {
      if(range.startOffset == range.startContainer.childNodes.length) {
        if(range.startContainer.nextSibling) {
          let tw = document.createTreeWalker(range.startContainer.nextSibling);
          while(tw.firstChild()) {
            startNode = tw.currentNode;
          }
        } else {
          throw "Not sure how to set startNode. We begin at the end of our startContainer, but it has no next sibling"
        }
      } else {
        startNode = range.startContainer.childNodes[range.startOffset];
      }
    } else {
      startNode = range.startContainer
    }
    if(!startNode) throw "Unable to find startNode"

    var endNode;
    if(range.endContainer.nodeType !== Node.TEXT_NODE) {
      if(range.endOffset == 0) {
        if(range.endContainer.previousSibling) {
          let tw = document.createTreeWalker(range.endContainer.previousSibling);
          while(tw.lastChild()) {
            endNode = tw.currentNode;
          }
        } else {
          throw "Not sure how to set the endNode. We stop at the beginning of our endContainer, but it has no previous sibling"
        }
      } else {
        endNode = range.endContainer.childNodes[range.endOffset - 1];
      }
    } else {
      endNode = range.endContainer
    }

    if(endNode?.contains(startNode)) {
      let r = document.createRange();
      if(startNode == range.startContainer) {
        r.setStart(range.startContainer, range.startOffset);
      } else {
        r.setStartBefore(startNode);
      }
      if(endNode == range.endContainer) {
        r.setEnd(range.endContainer, range.endOffset);
      } else {
        r.setEndAfter(endNode);
      }
      return [r]
    }

    while(treeWalker.currentNode !== startNode) {
      let currentNode = treeWalker.currentNode;

       if(!currentNode.contains(range.startContainer)) {
         treeWalker.nextSibling();
       } else {
         treeWalker.nextNode();
       }
    }

    /**
     * @type Range | null
     */
    let currentRange = document.createRange();
    currentRange.setStart(range.startContainer, range.startOffset);
    currentRange.setEndAfter(range.startContainer);
    while(!treeWalker.currentNode.contains(endNode)) {
      if(!currentRange) {
        currentRange = document.createRange();
        currentRange.setStartBefore(treeWalker.currentNode);
        currentRange.setEndBefore(treeWalker.currentNode);
      }
      while(!treeWalker.currentNode.contains(endNode)) {
        if(KNOWN_BLOCK_ELEMENTS[treeWalker.currentNode.nodeName]) {
          currentRange.setEndBefore(treeWalker.currentNode);
          if(!currentRange.collapsed) rangesToHighlight.push(currentRange);
          rangesToHighlight = rangesToHighlight.concat(this.blockToInlineRanges(treeWalker.currentNode));
          currentRange = document.createRange();
          currentRange.setStartAfter(treeWalker.currentNode);
          currentRange.setEndAfter(treeWalker.currentNode);
        } else {
          currentRange.setEndAfter(treeWalker.currentNode);
        }

        if(!treeWalker.nextSibling()) {
          break;
        }
      }

      if(!currentRange.collapsed) {
        rangesToHighlight.push(currentRange);
      }

      if(treeWalker.currentNode.contains(endNode)) {
        break;
      }
      treeWalker.nextNode();
      currentRange = null;
    }

    if(treeWalker.currentNode == endNode) {
      currentRange = document.createRange();
      currentRange.setStartBefore(endNode);
      if(range.endContainer.nodeType !== Node.TEXT_NODE) {
        currentRange.setEndAfter(endNode);
      } else {
        currentRange.setEnd(range.endContainer, range.endOffset);
      }
      rangesToHighlight.push(currentRange);
      return rangesToHighlight;
    }

    currentRange = null;
    while(treeWalker.nextNode()) {
      while(!treeWalker.currentNode.contains(endNode)) {
        if(!currentRange) {
          currentRange = document.createRange();
          currentRange.setStartBefore(treeWalker.currentNode);
          currentRange.setEndBefore(treeWalker.currentNode);
        }
        // include nodes that come before the endNode
        if(KNOWN_BLOCK_ELEMENTS[treeWalker.currentNode.nodeName]) {
          currentRange.setEndBefore(treeWalker.currentNode);
          if(!currentRange.collapsed) rangesToHighlight.push(currentRange);
          currentRange = null;
          rangesToHighlight = rangesToHighlight.concat(this.blockToInlineRanges(treeWalker.currentNode));
          if(!treeWalker.nextSibling()) {
            break
          }
        } else {
          currentRange.setEndAfter(treeWalker.currentNode);
          if(!treeWalker.currentNode.nextSibling) {
            rangesToHighlight.push(currentRange);
            currentRange = null;
          }

          if(!treeWalker.nextSibling()) {
            break;
          };
        }
      }

      if(currentRange && !currentRange.collapsed){
        rangesToHighlight.push(currentRange);
      }

      if(treeWalker.currentNode == endNode) {
        currentRange = document.createRange();
        currentRange.setStartBefore(endNode);
        if(range.endContainer.nodeType !== Node.TEXT_NODE) {
          currentRange.setEndAfter(endNode);
        } else {
          currentRange.setEnd(range.endContainer, range.endOffset);
        }
        rangesToHighlight.push(currentRange);
        break;
      }
    }
    return rangesToHighlight;
  }

  /**
   * @param {Node} node
   * @returns Range[]
   */
  blockToInlineRanges(node) {
    let ranges = [];
    let range = document.createRange();
    range.selectNodeContents(node);

    node.childNodes.forEach((child) => {
      if(KNOWN_BLOCK_ELEMENTS[child.nodeName]) {
        range.setEndBefore(child);
        if(!range.collapsed) {
          ranges.push(range);
        }

        range = document.createRange();
        range.setStartAfter(child);
        ranges = ranges.concat(this.blockToInlineRanges(child));
      }
    });

    if(!KNOWN_BLOCK_ELEMENTS[node.lastChild]) {
      ranges.push(range);
    }

    return ranges;
  }

  /**
   * @param {Range} range
   * @param {Highlight} highlight
   */
  highlightRange(range, highlight, select=true) {
    let highlightElement = document.createElement("span");
    highlightElement.classList.add(this.highlightClass);
    highlightElement.classList.add(highlight.color);
    highlightElement.dataset.id = String(highlight.id);
    if(this.isEquationRange(range)) {
      highlightElement.style.display = "inline-block";
    }
    range.surroundContents(highlightElement);
    if(select) {
      this.setSelectedHighlightId(highlight.id);
    }
  }

  /**
   *
   * @param {number} id
   * @param {string} color
   */
  highlightSelection(id, color, select=true) {
    let selection = document.getSelection();
    if(!selection) {
      throw "No Selection"
    }

    var range = selection.getRangeAt(0);

    this.renderHighlight({
      range: range,
      id: id,
      color: color
    }, select);

    selection.removeAllRanges();
  }

  /**
   * @param {number} id
   */
  deleteSelectionById(id) {
    this.element.querySelectorAll(`.${this.highlightClass}`).forEach((element) => {
      console.log(element)
      if(element["dataset"] && element["dataset"].id == String(id) ) {
        console.log("found");
        Array.prototype.slice.call(element.childNodes).forEach((child) => {
          element.parentNode?.insertBefore(child, element)
        })
        console.log(element)
        element.remove();
      }
    })
  }

  getCurrentMarkup() {
    let clonedNode = this.element.cloneNode(true);
    if(clonedNode instanceof HTMLElement) {
      clonedNode.querySelectorAll(`.${this.highlightClass}.selected`).forEach((elem) => {
        elem.classList.remove("selected");
      });

      return clonedNode.innerHTML;
    }
    return this.element.innerHTML;
  }

  getOrderedHighlightIds() {
    /**
     * @type number[]
     */
    let ids = [];
    this.element.querySelectorAll(`.${this.highlightClass}`).forEach((element) => {
      if(!element["dataset"]) return;
      let id = Number(element["dataset"].id);
      if(ids.indexOf(id) >= 0) return;
      ids.push(id);
    })
    return ids;
  }

  selectionIsInBounds() {
    let selection = document.getSelection();
    if(!selection) return false;
    if(selection.isCollapsed) return false;

    let range = selection.getRangeAt(0);
    let endContainer = range.endOffset === 0 ? range.endContainer.previousSibling : range.endContainer;
    return this.element.contains(range.startContainer) && this.element.contains(endContainer);
  }

  /**
   * 
   * @param {MouseEvent} e 
   */
  _handleMouseUp(e) {
    let selection = document.getSelection()
    if(selection && !selection.isCollapsed && !this.isEquationRange(selection.getRangeAt(0))) {
      return true;
    }

    if(!e.target) return;
    if(e.target instanceof HTMLImageElement && e.target.classList.contains("equation") && !e.target.closest(`.${this.highlightClass}`)) {
      document.getSelection()?.removeAllRanges();
      let range = document.createRange();
      range.selectNode(e.target);
      document.getSelection()?.addRange(range);
      return
    }
    /**
     * @type HTMLSpanElement | null
     */
    //@ts-ignore
    let closest = e.target.closest(`.${this.highlightClass}`);

    if(closest && this.element.contains(closest)) {
      this.setSelectedHighlightId(Number(closest["dataset"].id))
      this.onHighlightSelection(Number(closest["dataset"].id));
    } else if(!closest) {
      if(this.deselectOnOutsideClick) {
        let selected = this.element.querySelectorAll("." + this.highlightClass + ".selected")
        
        selected.forEach((s) => {
          s.classList.remove("selected");
        })
        if(selected.length > 0) {
          this.onDeselect(parseInt(selected[0]["dataset"].id));
        } 
      }
    }
  }

  setupMouseUpEvent() {
    document.addEventListener("click", this.handleMouseUp)
  }

  destroy() {
    document.removeEventListener("click", this.handleMouseUp)
  }

  forceDeselect() {
    let selected = this.element.querySelector("." + this.highlightClass + ".selected")
    
    if(selected) {
      selected.classList.remove("selected");
    }
  }

  /**
   * 
   * @param {Range} range 
   * @returns boolean
   */
  isEquationRange(range) {
    //both start and end containers must be elements
    if(range.startContainer.nodeType == Node.TEXT_NODE || range.endContainer.nodeType == Node.TEXT_NODE) {
      return false;
    }

    //one element selected
    if(range.endOffset - range.startOffset != 1) {
      return false;
    }

    //start and end container must be the same
    if(range.startContainer !== range.endContainer) {
      return false;
    }

    //must have a .equation selected
    let child = range.startContainer.childNodes[range.startOffset];
    if(child instanceof HTMLImageElement && child.classList.contains("equation")) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *
   * @param {number | null} id
   */
  setSelectedHighlightId(id) {
    let stringId = id == null ? "" : String(id)
    this.element.querySelectorAll(`.${this.highlightClass}`).forEach((el) => {
      if(el["dataset"].id == stringId) {
        el.classList.add("selected");
      } else {
        if(el.classList.contains("selected")) {
          el.classList.remove("selected");
        }
      }
    })
  }

  hideHighlights(bool=true) {
    if(bool) {
      this.element.classList.add("hide-comments")
    } else {
      this.element.classList.remove("hide-comments")
    }
  }

  /**
   *
   * @param {number} highlightId
   */
  scrollToHighlight(highlightId) {
    let stringId = String(highlightId);
    /**
     * @type HTMLElement[]
     */
    let highlights = Array.prototype.slice.apply(this.element.querySelectorAll(`.${this.highlightClass}`));
    let firstHighlight = highlights.find((el) => el["dataset"].id == stringId)
    if(firstHighlight) {
      this.element.scrollTo(0, firstHighlight.offsetTop)
    }
  }
}

