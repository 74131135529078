import { h, RefObject, Fragment } from 'preact';
import { createPortal } from 'preact/compat';
import { icon } from '@fortawesome/fontawesome-svg-core'
import { library} from '@fortawesome/fontawesome-svg-core'
import { faEye, faCheck } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
library.add(faEye, faCheck)
const eye = icon({prefix: "fas", iconName: "eye"});
const check = icon({prefix: "fas", iconName: "check"})

/**
 * @type Object.<number, HTMLElement>
 **/
const inputElementsByInputIdDefaultValue = {}

/**
 *  @typedef {import("../base-component").Props} Props
 */
/**
 *  @typedef {import("../../shared-types").StudentResponse} StudentResponse
 */

/**
 *
 * @param {Props} props
 */
export default function (props) {
  let [inputElementsByInputId, setInputElementsByInputId] = useState(inputElementsByInputIdDefaultValue);


  let body = useMemo(() => {
    let parser = new DOMParser();
    let dom = parser.parseFromString(props.item.body, "text/html");
    dom.querySelectorAll(".fill-in-the-blank").forEach((span) => {
      span.innerHTML = "" 
    });
    return dom.body.innerHTML
  }, [props.item.body])

  /**
   * @type RefObject.<HTMLDivElement>
   */
  let containerRef = useRef();
  let scoringMode = props.item.correctResponse?.scoring_mode || "teacher_scored"
  let definition = props.item.correctResponse.definition;
  let maxCreditDefs = definition?.reduce(
    /**
    * @param {Object.<number, import("../../shared-types").Definition[]>} obj
    * @param {import("../../shared-types").Definition} def
    **/
    (obj, def) => {
    if(!obj[def.input_id]) {
      obj[def.input_id] = []
    }
    if(scoringMode == "spread_points_evenly" || scoringMode == "all_or_nothing") {
      obj[def.input_id].push(def)
    } else {
      if(obj[def.input_id][0]) {
        if(def.score > obj[def.input_id][0].score) {
          obj[def.input_id] = [def]
        } else if(def.score == obj[def.input_id][0].score) {
          obj[def.input_id].push(def)
        }
      } else {
        obj[def.input_id] = [def]
      }
    }

    return obj;
  }, {}) || {}; 


  useEffect(() => {
    if(!containerRef.current) {
      return
    }

    /**
     * @type Object.<number, HTMLElement>
     **/
    let inputElementsByInputId = {};
    containerRef.current.querySelectorAll("span.fill-in-the-blank").forEach(
      /**
       * 
       * @param {HTMLSpanElement} el 
       */
      (el) => {
        inputElementsByInputId[Number(el.dataset.gapIdentifier)] = el;
    })

    setInputElementsByInputId(inputElementsByInputId);
  }, [])

  return <div ref={containerRef} class="fill-in-the-blank-report-container">
    <div dangerouslySetInnerHTML={{__html: body || ""}}>
    </div>
    {(props.item.responseDeclaration.inputs || []).map((inputDef) => {
      let defs = maxCreditDefs[inputDef.id];
      return (
        <Answer
          node={inputElementsByInputId[inputDef.id]}
          defs={defs}
          showPoints={
            !props.hidePoints &&
            (scoringMode == "spread_points_evenly" ||
              scoringMode == "point_associations")
          }
          pointsPerInput={
            scoringMode == "spread_points_evenly" ? (props.item.correctResponse.max_score || 0) / (props.item.responseDeclaration.inputs?.length || 1) : undefined
          }
        />
      );
    })}
  </div>
}


/**
 * 
 * @param {{
 *   node: HTMLElement | null,
 *   defs: import("../../shared-types").Definition[],
 *   showPoints: boolean,
 *   pointsPerInput: number | undefined 
 * }}
 * props 
 */
function Answer(props) {
  let [popupOpen, setPopupOpen] = useState(false);
  if(!props.node) {
    return null
  }

  /**
   * @type any
   */
  let content = "";

  if(props.defs.length == 1) {
    let def = props.defs[0]
    let value = def.value;
    if(def.type == "range") {
      value = `${def.from} to ${def.to}`
    }
    content = <>
      {value}
      <br/>
      <span class="check correct"dangerouslySetInnerHTML={{__html: check.html.join()}}>
      </span>
      {props.showPoints ? <span class="score">{props.pointsPerInput || def.score}</span> : null }
    </>
  } else {
    content = <div class="popup static-choice" onClick={() => {setPopupOpen(!popupOpen)}}>
      <div>Click to See Answers</div>
      <div style='text-align:center;' dangerouslySetInnerHTML={{__html: eye.html.join()}}></div>
       <div class="popup-content" style={popupOpen ? {visibility: "visible"} : {visibility: "hidden"}}>
        <table>
          {props.defs.map((d) => {
            let value = d.value;
            if(d.type == "range") {
              value = `${d.from} to ${d.to}`
            }
            return (
              <tr>
                <td>
                  {value} {<span class="check correct" dangerouslySetInnerHTML={{__html: check.html.join()}}></span>} {props.showPoints ? <span class="score">{props.pointsPerInput || d.score}</span> : null }
                </td>
              </tr>
            )
          })}
        </table>
      </div>
    </div>
  }

  return (
    createPortal(
      <span class="fill-in-the-blank-report-response">
        {content}
      </span>,
      props.node
    )
  )
}