import InlineChoiceComponent from "../inline-choice-component.jsx";
import { h, render } from "preact";
import copy from "fast-copy";
//@ts-ignore
export default class InlineChoiceReportComponent extends InlineChoiceComponent {

  componentDidMount() {
    this.props.didRender();
    if (!(this.base instanceof HTMLElement)) {
      throw ("expected base to be an html element");
    }

    this.base.querySelectorAll(".inline-choice").forEach(
      /**
       * @param {HTMLSelectElement} inlineChoiceElement
       */
      (inlineChoiceElement) => {
        if (inlineChoiceElement instanceof HTMLSelectElement) {

          var container = document.createElement('SPAN');
          inlineChoiceElement.replaceWith(container);

          const gapId = inlineChoiceElement.dataset.gapIdentifier || "";

          let correctResponseGapMatches = this.correctResponseGapMatches(gapId, true);
          if (correctResponseGapMatches) {
            let choice = null;
            let studentResponseGapMatch = null;

            let inlineChoiceMatch = this.inlineChoiceMatch(gapId);
            if (inlineChoiceMatch) {
              choice = this.choice(inlineChoiceMatch.choice_id, gapId);
              studentResponseGapMatch = this.correctResponseGapMatch(gapId, inlineChoiceMatch.choice_id);
            }

            render(this.gapResponse(choice, correctResponseGapMatches, studentResponseGapMatch), container);
          }
          else {
            render(this.noAnswer(), container);
          }
        }
      });
  }

  render() {
    return this.wrapper(this.body());
  }
}
