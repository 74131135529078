
import interact, { makeNewRestrictedPosition } from "../../interact"
import { InteractEvent } from "@interactjs/types/index";
import { useEffect, useRef, useState } from "preact/hooks"
import {h, RefObject} from "preact";
import { xMark } from "../../icons.js";

/**
 * @typedef {Object} Options
 * @property {string} storagePrefix
 */

/**
 * @typedef {Options & {questionId: string, close: () => void}} Props 
 */


/**
 * @typedef {import("../../../essay-editor/app.js").default} EssayEditor
 */


/**
 * @type {any}
 */
const initialEssayEditorLibState = null;


/**
 * 
 * @param {Props} props 
 */
export default function Notepad(props) {
    let [essayEditorLib, setEssayEditorLib] = useState(initialEssayEditorLibState);
    /**
     * @type {RefObject.<HTMLDivElement>}
     */
    let editorContainerElementRef = useRef(null);
    /**
     * @type {RefObject.<HTMLDivElement>}
     */
    let floatingWindowRef = useRef(null);

    useEffect(() => {
        let position = {x: 0, y: 0};
        interact(".notepad-floating-window").draggable({
            allowFrom: ".notepad-floating-window-header",
            max: 1,
            autoScroll: {
                enabled: true
            },
            listeners: {
                /**
                 * 
                 * @param {InteractEvent} evt 
                 */
                move: (evt) => {
                   position = makeNewRestrictedPosition(evt, position); 
                   if(floatingWindowRef.current) {
                    floatingWindowRef.current.style.transform = `translate(${position.x}px, ${position.y}px)`
                   }
                },
            }
        });

        import("../../../essay-editor/app.js").then((editor) => {
            setEssayEditorLib(editor);
        })
    }, []);

    useEffect(() => {
        if(!essayEditorLib) return;
        let editor =  new essayEditorLib.default({
            editorContainerElement: editorContainerElementRef.current,
            editorType: "basic",
            onAutoSave: function(editor) {
                localStorage.setItem(`${props.storagePrefix}_${props.questionId}`, editor.getData())
            },
            initialData: localStorage.getItem(`${props.storagePrefix}_${props.questionId}`) 
        })

        return function cleanup() {
            editor.destroy();
            if(editorContainerElementRef.current) {
                editorContainerElementRef.current.innerHTML = "";
            }
        }
    }, [essayEditorLib, props.questionId, props.storagePrefix])

    return <div class="notepad-floating-window floating-window" ref={floatingWindowRef}>
        <div class="notepad-floating-window-header floating-window-header drag-handle">
            <div class="notepad-floating-window-header-title floating-window-header-title">
            Notes
            </div>
            <div class="notepad-floating-window-header-exit floating-window-header-exit" dangerouslySetInnerHTML={{__html: xMark}} onClick={props.close}></div>
        </div>
        <div class="notepad-editor-container">
            <div ref={editorContainerElementRef}></div>
        </div>
    </div>
}