import BaseComponent from "../base-component.js";
import copy from "fast-copy";

export default class InlineChoiceQuestionComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.studentResponse = copy(this.props.studentResponse) || {
      inline_choice: {
        inline_choice_matches: [],
      },
    };
  }

  componentDidMount() {
    this.props.didRender();
    if(this.base instanceof HTMLElement) {
      let handler = 
      /**
       * 
       * @param {InputEvent} event 
       */
      (event) => {
        if(event.target instanceof HTMLSelectElement) {
          let choiceId = Number(event.target.value);
          let gapId = Number(event.target.dataset.gapIdentifier);
          this.props.studentResponse
          let choice = this.props.item.data.attributes.response_declaration.choices?.find((c) => c.id == choiceId && c.gap_id == gapId);
          if(!choice) {
            return;
          }

          this.selectChoice(choice);
        }
      };

      this.base.querySelectorAll(".inline-choice").forEach(
        (select) => {
          if(!(select instanceof HTMLSelectElement)){ return; };

          select.addEventListener("change", handler);
          let gapId = Number(select.dataset.gapIdentifier);
          let existingSelection = this.studentResponse.inline_choice?.inline_choice_matches.find((match) => {
            return match.gap_id == gapId;
          });

          if(existingSelection) {
            /**
             * @type HTMLOptionElement | null
             */
            let option = select.querySelector(`option[value='${existingSelection.choice_id}']`);
            if(option) {
              option.selected = true
            }
          }
        }
      )
    }
  }

  /**
   * 
   * @param {import("../../shared-types").ItemChoice} choice 
   */
  selectChoice(choice) {
    if (!this.studentResponse.inline_choice || choice.gap_id == undefined) {
      return;
    }

    this.studentResponse = {
      inline_choice: {
        inline_choice_matches: this.studentResponse.inline_choice.inline_choice_matches.filter((m) => m.gap_id !== choice.gap_id)
      }
    }

    this.studentResponse = {
      inline_choice: {
        inline_choice_matches: [...(this.studentResponse.inline_choice?.inline_choice_matches || []), {
          gap_id: choice.gap_id,
          choice_id: Number(choice.id)
        }]
      }
    }

    this.props.responseDidChange(this.studentResponse);
  }

  render() {
    return this.wrapper(
      this.body()
    )
  }
}
