import { autoUpdate, computePosition, flip, shift } from '@floating-ui/dom';
import { useEffect, useState, useRef } from 'preact/hooks';
import { h, RefObject } from 'preact';
import { trash } from '../../icons';
import rangy from "rangy";
import "rangy/lib/rangy-classapplier";
import "rangy/lib/rangy-highlighter";
import "rangy/lib/rangy-textrange";

/**
 * @typedef {Object} Options
 * @property {Object.<string, HTMLElement>} [highlighterRoots]
 * @property {string} storagePrefix
 */

/**
 * @typedef {Options & {active: boolean}} Props 
 */

/**
 * @type {null | any}
 */
const initialSelectedHighlightState = null;

/**
 * 
 * @param {Props} props 
 * @returns 
 */
export default function Highlighter(props) {
    /**
     * @type {RefObject.<HTMLDivElement>}
     */
    let tooltipRef = useRef();

    /**
     * @type {RefObject.<Function>}
     */
    let autoUpdateCancelRef = useRef();

    /**
     * @type {RefObject.<Object.<string, any>>}
     */
    let highlighters = useRef();

    useEffect(() => {
        let newHighlighters = {};
        if (!highlighters.current) {
            highlighters.current = {};
        }

        let currentHighlighters = highlighters.current;

        Object.entries(props.highlighterRoots || {}).forEach(([key, elem]) => {
            if (currentHighlighters[key]) {
                newHighlighters[key] = currentHighlighters[key]
            } else {
                let classApplier = rangy.createClassApplier("student-highlight", {
                    elementProperties: {
                        className: "yellow",
                    }
                });

                let highlighter = rangy.createHighlighter(document, "TextRange");
                highlighter.addClassApplier(classApplier);
                newHighlighters[key] = highlighter;
            }
        })

        highlighters.current = newHighlighters;
    }, [props.highlighterRoots])

    useEffect(() => {
        return function cleanup() {
            if (autoUpdateCancelRef.current) {
                autoUpdateCancelRef.current();
            }
        }
    }, [])

    let [selectedHighlight, setSelectedHighlight] = useState(initialSelectedHighlightState);
    let [tooltipStyle, setTooltipStyle] = useState("");

    useEffect(() => {
        rangy.init();
    }, [])

    //When we become active, should see if there is a selection and clear it to avoid confusion
    useEffect(() => {
        if (props.active) {
            document.getSelection()?.removeAllRanges();
        }
    })

    //clear and select
    useEffect(() => {
        if (selectedHighlight) {
            let maybeTooltip = tooltipRef.current;

            if (!maybeTooltip) {
                return
            }
            let tooltip = maybeTooltip;

            document.querySelectorAll(".student-highlight.selected").forEach((h) => {
                h.classList.remove("selected");
            });

            let elements = selectedHighlight.getHighlightElements();
            elements.forEach((elem) => { elem.classList.add("selected") });

            if (autoUpdateCancelRef.current) {
                autoUpdateCancelRef.current();
            }

            tooltip.style.display = "block";

            autoUpdateCancelRef.current = autoUpdate(elements[0], tooltip, () => {
                computePosition(elements[0], tooltip, { placement: "top-start", middleware: [flip(), shift()] }).then(({ x, y }) => {
                    if (elements[0].classList.contains("selected") && elements[0].parentNode) {
                        setTooltipStyle("display: block; left: " + x + "px; top: " + y + "px;");
                    }
                });
            })
        } else {
            document.querySelectorAll(".student-highlight.selected").forEach((h) => {
                h.classList.remove("selected");
            });

            if (autoUpdateCancelRef.current) {
                autoUpdateCancelRef.current();
            }

            setTooltipStyle("display: none");
        }
    }, [selectedHighlight])

    useEffect(() => {
        if (!props.highlighterRoots) return;
        let roots = props.highlighterRoots;

        /**
         * @param {PointerEvent} evt 
         */
        let handlePointerUp = (evt) => {
            if (!highlighters.current) return;
            let selection = rangy.getSelection();

            function deselect() {
                setSelectedHighlight(null);
                setTooltipStyle("display: none");
                document.querySelectorAll(".student-highlight.selected").forEach((h) => {
                    h.classList.remove("selected");
                });
            }

            if (selection.rangeCount > 0 && !selection.isCollapsed) {
                let rootData = Object.entries(roots).find(([rootId, root]) => {
                    return root.contains(selection.getRangeAt(0).commonAncestorContainer)
                })

                if (!rootData) return;
                let rootId = rootData[0];
                let root = rootData[1];
                let rootContainerId = rootData[1].id;

                let highlighter = highlighters.current[rootId];
                let newHighlights = highlighter.highlightSelection("student-highlight", {
                    containerElementId: rootContainerId
                })

                removeIllegalHighlightElements(newHighlights)

                document.querySelectorAll(".student-highlight.selected").forEach((h) => {
                    h.classList.remove("selected");
                });
                selection.removeAllRanges();

                localStorage.setItem(props.storagePrefix + `_${rootId}`, highlighter.serialize());
            } else {
                if (evt.target && evt.target instanceof HTMLElement && !tooltipRef.current?.contains(evt.target)) {
                    let maybeHighlight = evt.target.closest(".student-highlight");
                    if (!maybeHighlight) { deselect(); return; };
                    let highlightElem = maybeHighlight;

                    let rootData = Object.entries(roots).find(([rootId, root]) => {
                        return root.contains(highlightElem)
                    })

                    if (!rootData) return;
                    if (!highlighters.current) return;
                    let highlighter = highlighters.current[rootData[0]];

                    let highlight = highlighter.getHighlightForElement(evt.target);
                    if (highlight) {
                        setSelectedHighlight(highlight);
                    }
                }
            }
        }

        if (props.active) {
            Object.entries(roots).forEach(([id, root]) => {
                root.classList.add("highlighter-active");
            })

            document.addEventListener("pointerup", handlePointerUp)
        }

        return () => {
            Object.entries(roots).forEach(([id, root]) => {
                root.classList.remove("highlighter-active");
            })
            document.removeEventListener("pointerup", handlePointerUp);
        }
    }, [props.storagePrefix, props.active, props.highlighterRoots]);

    useEffect(() => {
        if (!props.highlighterRoots) return;
        Object.keys(props.highlighterRoots).forEach((id) => {
            let maybeExistingRangeData = localStorage.getItem(props.storagePrefix + `_${id}`);
            if (maybeExistingRangeData) {
                if (!highlighters.current) return;
                let highlighter = highlighters.current[id];
                highlighter.deserialize(maybeExistingRangeData);
                removeIllegalHighlightElements(highlighter.highlights);
                document.getSelection()?.removeAllRanges();
            }
        })
    }, [props.highlighterRoots]);

    if (!props.highlighterRoots) return null;

    return <div ref={tooltipRef} id="highlighter-tooltip" style={tooltipStyle} onClick={(evt) => {
        if (selectedHighlight !== null) {
            let highlightElems = selectedHighlight.getHighlightElements();
            let rootData = Object.entries(props.highlighterRoots || {}).find(([id, elem]) => {
                return highlightElems.some((highlightElem) => {
                    return elem.contains(highlightElem);
                })
            });
            if (!rootData) return;
            let [rootId, root] = rootData;

            if (!props.highlighterRoots) return;
            if (!highlighters.current) return;
            let highlighter = highlighters.current[rootId];

            highlighter.removeHighlights([selectedHighlight]);

            highlightElems.forEach((e) => {
                e.childNodes.forEach((child) => {
                    e.parentElement.insertBefore(child, e)
                })

                let parent = e.parentElement;

                e.remove();
                parent.normalize();
            })

            localStorage.setItem(props.storagePrefix + `_${rootId}`, highlighter.serialize());
            setSelectedHighlight(null)
            if (autoUpdateCancelRef.current) {
                autoUpdateCancelRef.current()
            }

            setTooltipStyle("display: none;");
        }
    }} dangerouslySetInnerHTML={{ __html: trash }}>
    </div>
}


/**
 * 
 * @param {any[]} highlights 
 */
function removeIllegalHighlightElements(highlights) {
    highlights.forEach((highlight) => {
        let elements = highlight.getHighlightElements();
        elements.filter((e) => e.closest(".draggable-choice")).forEach((e) => {
            e.childNodes.forEach((child) => {
                e.parentElement.insertBefore(child, e)
            })

            e.remove();
        })
    })
}