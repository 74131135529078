import { h, RefObject } from 'preact';
import { icon } from '@fortawesome/fontawesome-svg-core'
import { useEffect, useRef } from 'preact/hooks';
const minus = icon({prefix: "fas", iconName: "minus"});
const times = icon({prefix: "fas", iconName: "times"});
const check = icon({prefix: "fas", iconName: "check"})

/**
 *  @typedef {import("../base-component").Props} Props
 */
/**
 *  @typedef {import("../../shared-types").StudentResponse} StudentResponse
 */

/**
 *
 * @param {Props} props
 */
export default function (props) {
  /**
   * @type RefObject<HTMLDivElement>
   */
  let containerRef = useRef();
  useEffect(() => {
    if(!containerRef.current) {
      return
    }

    let container = containerRef.current

    let scoringData = props.scoringData;

    /**
     * @type Object.<string, import("../../shared-types").FillInTheBlankInputScoringData>
     */
    let scoringDataByInputId = (scoringData?.inputs || []).reduce((obj, sd) => {
      obj[sd.id] = sd;
      return obj;
    }, {})

    let scoringMode = props.item.correctResponse?.scoring_mode || "teacher_scored";

    let hidePoints = props.hidePoints || scoringMode == "all_or_nothing" || scoringMode == "teacher_scored";

    (props.item.responseDeclaration.inputs || []).forEach((inputDef) => {
      let sd = scoringDataByInputId[inputDef.id]
      let response = (props.studentResponse?.fill_in_the_blank?.input_responses || []).find((r) => r.input_id == String(inputDef.id))
      let input = container.querySelector("input[data-gap-identifier=\"" + inputDef.id + "\"]");
      if(input && input instanceof HTMLInputElement) {
        let span = document.createElement("div")
        span.classList.add("fill-in-the-blank-report-response");
        let icon = "";
        let points = ""
        if(sd) {
          let outcome = "";
          if(sd.correct) {
            outcome = "correct";
          } else if(sd.points > 0) {
            outcome = "partial"
          } else {
            outcome = "incorrect"
          }


          if(outcome == "correct") {
            icon = check.html.join()
          } else if(outcome == "incorrect" || outcome == "omit") {
            icon = times.html.join();
          } else if(outcome == "partial") {
            icon = minus.html.join();
          }
          if(!hidePoints) {
            points = `<span class='score'>${outcome == "correct" || outcome == "partial" ? sd.points : 0}</span>`
          }
          span.classList.add(outcome);
        }
        span.innerHTML = (response?.text || "") +"<br/>"+(props.hideCorrectAnswers || scoringMode == "teacher_scored" ? "" : icon)+points
        input.replaceWith(span)
      }
    })
  }, [])

  return <div class="question-wrapper">
    <div class="fill-in-the-blank-report-container question-body" ref={containerRef} dangerouslySetInnerHTML={{__html: props.item.bodyWithSubstitutions || ""}}>
    </div>
  </div>
}