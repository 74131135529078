import BaseComponent from "../base-component.js";
import { h } from "preact";

class GriddedQuestionComponent extends BaseComponent {
  responseCollector() {
    var columns = this.props.item.responseDeclaration.grid_columns
    if(columns) {
      return h("div", {class: "response-collector"}, columns.map((column) => {
        if(column.type == "static" && column.value) {
          return h("div", {class: "response-col"},
            h("div", {class: "answer"}, h("div", {class: "static gridded-cell"}, column.value)))
        } else if( column.cells ) {
          return h("div", {class: "response-col"},
            h("div", {class: "answer"}, [h("input", {type: "text"}), ...column.cells.map((cell) => {
              return h("button", {class: "button gridded-cell"}, cell.value)
            })]))
        }
      }))
    }
  }

  render() {
    return(this.wrapper(this.body(), this.responseCollector()));

  }
}

export default GriddedQuestionComponent