import {renderEditor, renderToolbar, renderSaveAction} from "./template"
import throttle from "./throttle"
import "./array-from-polyfill";
import './editor-styles.css';
import "../src/styles/base.css"
import "../src/styles/color-picker.css"

const DEFAULT_SETTINGS = {
  brushSize: 2,
  toolType: "pen",
  brushColor: "Black"
}; 

class DrawingEditor {
  /**
   * @param {string | Element} elemOrSelector 
   * @param {{
   *  enableSaveWorkflow?: boolean,
   *  enableDrawingToggle?: boolean,
   *  readOnly?: boolean,
   *  height?: number,
   *  width?: number,
   *  onChange?: function,
   *  onSave?: function
   *  customizations?: {
   *    showDrawingLabel?: string,
   *    hideDrawingLabel?: string 
   *  }
   * }} options 
   * @returns DrawingEditor
   */
  constructor(elemOrSelector, options={}) {
    this._showDrawing = true;
    this._lines = []
    this._undos = []
    this._settings = Object.assign({}, DEFAULT_SETTINGS); 

    this.customizations = {
      showDrawingLabel: "Show Annotations",
      hideDrawingLabel: "Hide Annotations",
      ...(options.customizations || {})
    }
    this._readOnly = options.readOnly || false;

    /**
     * @type null | Element
     */

    var rootElement = null;
    if(typeof elemOrSelector == "string") {
      rootElement = document.querySelector( elemOrSelector );
    } else {
      rootElement = elemOrSelector;
    }

    if(!rootElement) {
      throw(`Could not locate element with selector ${elemOrSelector}`)
    }

    if(!elemOrSelector) {
      throw "a valid element or selector must be passed"
    }

    rootElement.innerHTML = renderEditor({
      hideDrawingLabel: this.customizations.hideDrawingLabel,
      enableDrawingToggle: options.enableDrawingToggle || false,
      readOnly: this._readOnly,
      height: options["height"] || 300,
      width: options["width"] || 600 
    })
    
    this._options = options;
    this._onSave = options.onSave || function() {};
    this._container = rootElement;
    this._canvas = this._container.querySelector("canvas");

    /**
     * @type HTMLButtonElement | null
     */
    var drawingToggleAction = this._container.querySelector(".toggle-drawing-action")
    if(!drawingToggleAction) {
      throw("Missing Required Element")
    }
    this._drawingToggleAction = drawingToggleAction;
    this.setupDrawingToggle();

    /**
     * @type HTMLImageElement | null
     */
    this._image = this._container.querySelector(".drawing-background");
    /**
     * @type HTMLImageElement | null
     */
    this._readonlyLayer = this._container.querySelector(".readonly-layer");

    /**
     * @type HTMLDivElement | null
     */
    var saveAction = this._container.querySelector(".save-actions-button");

    this._drawingToolbar = this._container.querySelector(".drawing-toolbar");

    if(!(this._canvas && this._image && this._readonlyLayer && this._drawingToolbar && saveAction)) {
      throw("Missing Required Element")
    }

    this._saveAction = saveAction

    var ctx = this._canvas.getContext("2d");
    if(ctx) {
      this._ctx = ctx
    } else {
      throw("Could not getContext")
    }
    this._throttledHandlePointerMove = throttle(this._handlePointerMove, 5).bind(this);
    if(options["enableSaveWorkflow"]) {
      //@ts-ignore
      saveAction.parentElement.style.display = "inline-block";

      saveAction.addEventListener("click", (e) => {
        this._saveActionClickHandler(e);
      })
    }

    if(this._readOnly) {

    } else {
      this._setupPointerEvents()
      this._setupUIEvents()
    }
  }

  toDataURL() {
    return this._canvas.toDataURL();
  }

  fromDataURL(dataURL) {
    if(!dataURL) return;
    var img = new Image;
    img.setAttribute('crossorigin', 'anonymous');
    img.src = dataURL;
    this._loadedImage = img;
    img.onload = () => {
      if(this._options.enableDrawingToggle && this._readOnly) {
        this._drawingToggleAction.style.display = "inline-block";
      }
      this._drawLines();
    }
  }

  setBackgroundSrc(src) {
    if(!src || !this._image) return;
    this._image.src = src;
  }

  setReadOnlyLayerSrc(src) {
    if(!src || !this._readonlyLayer) return;
    this._readonlyLayer.src = src;
    this._readonlyLayer.style.display = "inline-block";
  }

  destroy() {
    //@ts-ignore
    this._ctx = null;
    if(this._canvas) {
      this._canvas.width = 0;
      this._canvas.height = 0;
      this._canvas.remove();
      //@ts-ignore
      this._canvas = null;
    }
  }

  /**
   * 
   * @param {boolean} tf 
   */
  _setReadOnly(tf) {
    this._readOnly = tf

    if(!this._readOnly) {
      this._settings = Object.assign({}, DEFAULT_SETTINGS);
      this._canvas.className = "using-point-" + this._settings.brushSize;
      this._drawingToolbar.innerHTML = renderToolbar();
      this._drawingToggleAction.style.display = "none";
      this._container.querySelector(".drawing-editor")?.classList.remove("readonly");
      this._setupPointerEvents();
      this._setupUIEvents()
      this._saveAction.innerHTML = renderSaveAction(this._readOnly);
    } else {
      this._drawingToolbar.textContent = '';
      if(this._options.enableDrawingToggle) {
        this._drawingToggleAction.style.display = "inline-block";
      }

      this._container.querySelector(".drawing-editor")?.classList.add("readonly");
      if(this._options.enableSaveWorkflow) {
        this._saveAction.innerHTML = renderSaveAction(this._readOnly);
      }
    }
  }

  /**
   * 
   * @param {Event} event 
   */
  _saveActionClickHandler(event) {
    if(this._disableSaveActionClickHander) {
      return
    }

    if(this._readOnly) {
      this._setReadOnly(false)
    } else {
      this._container.querySelector(".drawing-editor")?.classList.add("readonly");
      this._disableSaveActionClickHander = true;
      this._readOnly = true;

      /**
       * @type Promise | undefined
       */
      var maybePromise = this._onSave();
      if(maybePromise && maybePromise.then) {
        this._saveAction.classList.add("loading");
        maybePromise.then(() => {
          this._saveAction.classList.remove("loading");
          this._disableSaveActionClickHander = false;
          this._setReadOnly(true);
        }, () => {
          this._saveAction.classList.remove("loading");
          this._disableSaveActionClickHander = false;
          this._setReadOnly(true);
          console.log("onSave was rejected");
        });
      } else {
        this._setReadOnly(true);
        this._disableSaveActionClickHander = false;
      } 
    }
  }

  /**
   * 
   * @param {PointerEvent} event 
   */
  _recordPoint(event) {
    this._lines[this._lines.length - 1].points.push(this._createPoint(event));
  }

  /**
   * 
   * @param {PointerEvent} event 
   * @returns {{
   *  x: number,
   *  y: number
   * }}
   */
  _createPoint(event) {
    const x = event.clientX;
    const y = event.clientY;
    const rect = this._canvas.getBoundingClientRect();
    return {
      x: x - rect.left,
      y: y - rect.top
    };
  }

  /**
   * 
   * @param {PointerEvent} event 
   */
  _beginNewLine(event) {
    console.log("beginNewLine")
    var brushSizeMultipler = 1;
    if(this._settings.toolType == "eraser") {
      brushSizeMultipler = 1.75;
    }
    this._lines.push({points: [this._createPoint(event)], settings: {
      brushSize: this._settings.brushSize * brushSizeMultipler,
      toolType: this._settings.toolType,
      brushColor: this._settings.brushColor
    }});
  }

  /**
   * 
   * @param {PointerEvent} event 
   */
  _strokeBegin(event) {
    this._undos = [];
    this._beginNewLine(event);
  }

  _midPointBtw(p1, p2) {
    return {
      x: p1.x + (p2.x - p1.x) / 2,
      y: p1.y + (p2.y - p1.y) / 2
    };
  }

  _drawLines() {
    this._ctx.clearRect(0, 0, this._ctx.canvas.width, this._ctx.canvas.height);
    if(this._loadedImage) {
      this._ctx.globalCompositeOperation = "source-over";
      this._ctx.drawImage(this._loadedImage, 0, 0);
    }

    this._lines.forEach((line) => {
      var lineStart = line.points[0];
      var lineEnd = line.points[1]
      this._ctx.beginPath();
      this._ctx.moveTo(lineStart.x, lineStart.y);
      this._ctx.lineWidth = line.settings.brushSize;
      if(line.settings.toolType == "pen" || line.settings.toolType == "line") {
        this._ctx.globalCompositeOperation = "source-over";
        this._ctx.strokeStyle = line.settings.brushColor;
      } else if (line.settings.toolType == "eraser") {
        this._ctx.globalCompositeOperation = "destination-out";
        this._ctx.strokeStyle = "rgba(0,0,0,1)";
      }

      for (var i = 1, len = line.points.length; i < len; i++) {
        // we pick the point between pi+1 & pi+2 as the
        // end point and p1 as our control point
        var midPoint = this._midPointBtw(lineStart, lineEnd);
        this._ctx.quadraticCurveTo(lineStart.x, lineStart.y, midPoint.x, midPoint.y);
        lineStart = line.points[i];
        lineEnd = line.points[i+1];
      }
      this._ctx.lineTo(lineStart.x, lineStart.y);
      this._ctx.stroke();
    });
  }

  /**
   * 
   * @param {PointerEvent} event 
   */
  _strokeMoveUpdate(event) {
    if(this._settings.toolType === "line") {
      this._lines[this._lines.length - 1].points[1] = this._createPoint(event);
      this._drawLines();
    } else {
      this._recordPoint(event);
      this._drawLines()
    }
  }

  _strokeEnd() {
    if(this._options.onChange) {
      this._options.onChange(this);
    }
  }

  /**
   * 
   * @param {PointerEvent} event 
   */
  _handlePointerDown(event) {
    if(!event.isPrimary) return true;
    this._strokeBegin(event);
  }

  /**
   * 
   * @param {PointerEvent} event 
   */
  _handlePointerMove(event) {
    if (event.isPrimary && event.buttons == 1) {
      this._strokeMoveUpdate(event);
    } else {
      return true;
    }
  }

  /**
   * 
   * @param {PointerEvent} event 
   */
  _handlePointerUp(event) {
    if (event.isPrimary) {
      this._strokeEnd();
    } else {
      return true;
    }
  }

  _setupPointerEvents() {
    var wrap = (func) => {
      return (e) => {
        e.preventDefault();
        if(!this._readOnly) {
          return func(e)
        }
      }
    }

    //@ts-ignore
    this._canvas.style.msTouchAction = 'manipulation';
    this._canvas.style.touchAction = 'manipulation';

    this._canvas.addEventListener("touchstart",  function(event) {event.preventDefault()})
    this._canvas.addEventListener("touchmove",   function(event) {event.preventDefault()})
    this._canvas.addEventListener("touchend",    function(event) {event.preventDefault()})
    this._canvas.addEventListener("touchcancel", function(event) {event.preventDefault()})

    this._canvas.addEventListener('pointerdown', wrap(this._handlePointerDown.bind(this)), { passive: false })
    this._canvas.addEventListener('pointermove', wrap(this._throttledHandlePointerMove), { passive: false })
    this._canvas.addEventListener('pointerup', wrap(this._handlePointerUp.bind(this)), { passive: false })
  }

  _handleBrushSizeChange(event) {
    this._settings.brushSize = Number(event.target.dataset.value);
  }

  _undo() {
    if(!this._lines.length || this._readOnly) { return };
    this._undos.push(this._lines.pop());
    this._drawLines();
    this._strokeEnd();
  }

  _redo() {
    var line = this._undos.pop();
    if(!line || this._readOnly) { return };
    this._lines.push(line);
    this._drawLines();
    this._strokeEnd();
  }

  _makeActive(elem) {
    if(!elem.className.includes("active")) {
      elem.className += " active";
    }
  }

  _makeInactive(elem) {
    elem.className =
      elem.className.replace(new RegExp('(?:^|\\s)active(?:\\s|$)'), ' ').trim();
  }
 
  _setupUIEvents() {
    var sizeControls = [...this._container.querySelectorAll(".drawing-size-control")];
    sizeControls.forEach((elem) => {
      elem.addEventListener("click", (e) => {
        sizeControls.forEach((el) => this._makeInactive(el));
        this._makeActive(elem);
        this._handleBrushSizeChange(e);
        this._canvas.className = "using-point-" + this._settings.brushSize;
        e.preventDefault();
      })
    });
   
    var drawTool = this._container.querySelector(".drawing-draw-tool");
    var eraseTool = this._container.querySelector(".drawing-erase-tool");
    var lineTool = this._container.querySelector(".drawing-line-tool");

    if(eraseTool) {
      eraseTool.addEventListener("click", (e) => {
        this._makeInactive(drawTool);
        this._makeInactive(lineTool);
        this._makeActive(eraseTool);
        /**
         * @type HTMLButtonElement | null
         */
        this._preEraseBrush = this._container.querySelector(".drawing-size-control.active");
        /**
         * @type HTMLButtonElement | null
         */
        let elem = this._container.querySelector(".point-20")
        if(!elem) {
          throw("Failed to find .point-20");
        }
        sizeControls.forEach((el) => this._makeInactive(el));
        this._makeActive(elem);
        this._settings.brushSize = Number(elem.dataset.value);
        this._settings["toolType"] = "eraser";
        this._canvas.className = "using-point-" + this._settings.brushSize;
        e.preventDefault();
      });
    }

    if(lineTool) {
      lineTool.addEventListener("click", (e) => {
        this._makeInactive(drawTool);
        this._makeInactive(eraseTool);
        this._makeActive(lineTool);

        if(this._settings.toolType == "pen") {
          /**
           * @type HTMLButtonElement | null
           */
          this._preEraseBrush = this._container.querySelector(".drawing-size-control.active");
        }
        /**
         * @type HTMLButtonElement | null
         */
        let elem = this._container.querySelector(".point-2")
        if(!elem) {
          throw("Failed to find .point-2");
        }
        sizeControls.forEach((el) => this._makeInactive(el));
        this._makeActive(elem);
        this._settings.brushSize = Number(elem.dataset.value);
        this._settings["toolType"] = "line";
        this._canvas.className = "using-point-" + this._settings.brushSize;
        e.preventDefault();
      });
    }

    if(drawTool) {
      drawTool.addEventListener("click", (e) => {
        this._makeInactive(eraseTool);
        this._makeInactive(lineTool);
        this._makeActive(drawTool);
        if(this._preEraseBrush) {
          sizeControls.forEach((el) => this._makeInactive(el));
          this._makeActive(this._preEraseBrush);
          this._settings.brushSize = Number(this._preEraseBrush.dataset.value);
          this._canvas.className = "using-point-" + this._settings.brushSize;
          e.preventDefault();
        }
        this._settings["toolType"] = "pen";
      });
    }

    var undoAction = this._container.querySelector(".drawing-undo-action")
    if(undoAction) {
      undoAction.addEventListener("click", (e) => {
        this._undo()
        e.preventDefault();
      })
    }

    var redoAction = this._container.querySelector(".drawing-redo-action")
    if(redoAction) {
      redoAction.addEventListener("click", (e) => {
        this._redo()
        e.preventDefault();
      })
    }

    var db = this._container.querySelector(".drawingdropbtn");
    if(db) {
      var dropDownButton = db;
      /**
       * @type HTMLDivElement | null
       */
      var c = this._container.querySelector(".drawing-dropdown-content");
      if(!c) {
        throw("Failed to find dropdown-content");
      }
      var content = c;

      dropDownButton.addEventListener("click", (e) => {
        var display = content.style.display;
        if(display != "block") {
          content.style.display = "block"
        } else {
          content.style.display = "none"
        }
        e.preventDefault();
      });

      /**
       * @type NodeListOf<HTMLDivElement>
       */
      var drawingColors = this._container.querySelectorAll(".drawing-color")
      drawingColors.forEach((elem) => {
        elem.addEventListener("click", (e) => {
          e.stopPropagation();
          this._settings.brushColor =  elem.style["background-color"];       
          content.style.display = "none";
          (dropDownButton.querySelector("span") || {style: {}}).style["background-color"] = elem.style["background-color"];
          e.preventDefault();
        });
      });
    }

  }

  setupDrawingToggle() {
    this._drawingToggleAction.addEventListener("click", (e) => {
      this._showDrawing = !this._showDrawing;
      this._drawingToggleAction.innerText = this._showDrawing ? this.customizations.hideDrawingLabel : this.customizations.showDrawingLabel;
      this._canvas.style.display = this._showDrawing ? "initial" : "none";
      e.preventDefault();
    })
  }
}

export default DrawingEditor

window["DrawingEditor"] = DrawingEditor
