import NullComponent from "../components/questions/null-component-question.js"
import {ComponentClass, FunctionComponent} from "preact";
class Item {
  /**
   * @param {import("../shared-types").ItemJSON} item
   */
  constructor(item) {
    this.data = item.data;
    this.attributes = this.data.attributes;
    this.id = this.data.id;
    this.body = this.attributes.body;
    /** @type{string|undefined} */
    this.bodyWithSubstitutions = undefined
  }

  /**
   * @param {string} mode
   * @returns {ComponentClass | FunctionComponent}
   */
  component(mode) {
    return NullComponent;
  }
}

export default Item;