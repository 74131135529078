import FillInTheBlankQuestion from "../../models/fill-in-the-blank-question.js";
import BaseComponent from "../base-component.js";

class FillInTheBlankQuestionComponent extends BaseComponent {
  /**
   *  @typedef {import("../base-component").Props} Props
   */
  /**
   *  @typedef {import("../../shared-types").StudentResponse} StudentResponse
   */

  /**
   *
   * @param {Props} props
   */
  constructor(props) {
    super(props);
    this.state = {
      studentResponse: props.studentResponse
    };

    if(props.item instanceof FillInTheBlankQuestion) {
      this.question = props.item;
    } else {
      throw("Expected a instance of FillInTheBlankQuestion")
    }
  }

  componentDidMount() {
    if(this.state.studentResponse) {
      this.state.studentResponse.fill_in_the_blank?.input_responses.forEach(response => {
        if(this.base instanceof HTMLElement) {
          let input = this.base.querySelector("input[data-gap-identifier=\"" + response.input_id + "\"]");
          if(input) {
            input.setAttribute('value', response.text || "");
          }
        }
      });
    }

    if(this.base instanceof HTMLElement) {
      let numericRegex = new RegExp(/^(?:(?:-|\+)?\d+(?:\.\d*)?(?:e-?\d+)?|\.\d+)$/)
      this.base.querySelectorAll(".fill-in-the-blank").forEach((input) => {
        if(input instanceof HTMLInputElement) {
          if(this.props.readOnly) {
            input.setAttribute('readonly', "true");
            input.tabIndex = -1;
          } else {
            let inputId = parseInt(input.getAttribute("data-gap-identifier") || "-1")
            let inputConfig = this.question.configForInput(inputId)
            /**
             *
             * @param {InputEvent} evt
             * @returns
             */
            let changeHandler = (evt) => {

              if(!evt.target) return;

              if(evt.target instanceof HTMLInputElement) {
                let value = evt.target.value
                let inputId = input.dataset.gapIdentifier;
                let studentResponse = this.state.studentResponse;
                if(!studentResponse || !studentResponse.fill_in_the_blank) {
                  studentResponse = {
                    fill_in_the_blank: {
                      input_responses: []
                    }
                  }
                }

                let existing = studentResponse.fill_in_the_blank?.input_responses.find((res) => {
                  return res.input_id == inputId;
                })

                /**
                 * @type StudentResponse | null
                 */
                let updated = null;

                if(existing && studentResponse.fill_in_the_blank) {
                  updated = {
                    fill_in_the_blank: {
                      input_responses: studentResponse.fill_in_the_blank.input_responses.map((input_response) => {
                        if(input_response.input_id == existing?.input_id) {
                          return {...existing, text: value}
                        } else {
                          return input_response
                        }
                      })
                    }
                  }
                } else {
                  updated = {
                    fill_in_the_blank: {
                      input_responses: studentResponse.fill_in_the_blank?.input_responses.concat([{
                        input_id: inputId || "",
                        text: value
                      }]) || []
                    }
                  }
                }

                this.setState({studentResponse: updated})
                this.props.responseDidChange(updated)
              }
            }

            /**
             *
             * @param {InputEvent} evt
             * @returns
             */
            let inputHandler = (evt) => {
              let preProcessedInput = input.value;
              if(inputConfig?.max_length) {
                preProcessedInput = preProcessedInput.slice(0,inputConfig.max_length)
              }
              if(inputConfig?.type == "numeric") {
                preProcessedInput = preProcessedInput.trim().replace(",", "")
                let valid = preProcessedInput.match(numericRegex);
                if(valid) {
                  input.classList.remove("invalid")
                }
                else {
                  input.classList.add("invalid")
                }
              }
              if(preProcessedInput != input.value) {
                input.value = preProcessedInput
              }
            }

            input.addEventListener("change", changeHandler)
            input.addEventListener("input", inputHandler)
          }
        }
      })
    }
    this.props.didRender();
  }

  render() {
    return(
      this.wrapper(
        this.body()
      )
    );
  }
}

export default FillInTheBlankQuestionComponent
