import { render, h, Fragment } from "preact";
import { useState } from "preact/hooks";
import Tts from "./components/tools/tts.jsx"
import Highlighter from "./components/tools/highlighter.jsx"
import Notepad from "./components/tools/notepad.jsx"
import { volume, marker, note, calculator, fileVideo, file } from "./icons.js";
import "./styles/tool-menu.css"
import Calculator from "./components/tools/calculator.jsx";
import Embed from "./components/tools/embed.jsx";

export default class ToolsWrapper {
  /**
  * 
  * @typedef {Object} Options
  * @property {HTMLElement} element
  * @property {string} questionId
  * @property {import("./components/tools/tts").Options} [ttsOptions]
  * @property {import("./components/tools/highlighter").Options} [highlighterOptions]
  * @property {import("./components/tools/notepad").Options} [notepadOptions]
  * @property {import("./components/tools/calculator").Options} [calculatorOptions]
  * @property {import("./components/tools/embed").Options} [embedOptions]
  * @property {string} [dragContainer]
  * @property {string[]} enabledTools
  */

  /**
   * 
   * @param {Options} options 
   */
  constructor(options) {
    this.element = options.element;
    this.enabledTools = options.enabledTools;
    this.ttsOptions = options.ttsOptions;
    this.highlighterOptions = options.highlighterOptions;
    this.notepadOptions = options.notepadOptions;
    this.calculatorOptions = options.calculatorOptions;
    this.embedOptions = options.embedOptions;
    this.questionId = options.questionId;
    this.portalNode = document.createElement("div");
    this.dragContainer = options.dragContainer;
    this.toolMenuProps = {
      ttsOptions: this.ttsOptions,
      questionId: this.questionId,
      enabledTools: this.enabledTools,
      highlighterOptions: this.highlighterOptions,
      notepadOptions: this.notepadOptions,
      calculatorOptions: this.calculatorOptions,
      embedOptions: this.embedOptions,
      dragContainer: this.dragContainer 
    };

    this.initialRender = true;
  }

  render() {
    if (this.initialRender) {
      document.body.appendChild(this.portalNode);
      this.initialRender = false;
    }

    render(<ToolMenu {...this.toolMenuProps} portalNode={this.portalNode}/>, this.element)
  }

  destroy() {
    render(null, this.element)
    this.portalNode.remove()
  }

  /**
   * @param {Options} options 
   */
  update(options) {
    this.toolMenuProps = { ...this.toolMenuProps, ...options }
    this.render()
    return this.toolMenuProps;
  }
}

/**
 * @type {('tts' | 'highlighter' | 'notepad' | 'calculator' | 'embed_0' | 'embed_1' | 'embed_2' | 'embed_3' | 'embed_4' )[]}
 */
const initialActiveToolsState = [];

/**
 * @param {{
 * portalNode: HTMLElement,
 * questionId: string,
 * enabledTools: string[],
 * ttsOptions?: import("./components/tools/tts").Options,
 * highlighterOptions?: import("./components/tools/highlighter").Options,
 * notepadOptions?: import("./components/tools/notepad").Options,
 * embedOptions?: import("./components/tools/embed").Options,
 * calculatorOptions?: import("./components/tools/calculator").Options,
 * dragContainer?: string
 * }} props 
 */
function ToolMenu(props) {
  let [activeTools, setActiveTools] = useState(initialActiveToolsState);

  /**
   * @type {{elem: import("preact").JSX.Element, key: string}[]}
   */
  let components = [];

  let ttsActive = activeTools.includes("tts");
  let highlighterActive = activeTools.includes("highlighter");
  let notepadActive = activeTools.includes("notepad");
  let calculatorActive = activeTools.includes("calculator");
  let activeEmbeds = activeTools.filter((tool) => tool.includes("embed"));

  /**
   * 
   * @param {'highlighter' | 'tts' | 'notepad' | 'calculator' | 'embed_0' | 'embed_1' | 'embed_2' | 'embed_3' | 'embed_4' } tool 
   */
  const toggleTool = (tool) => {
    if(!activeTools.includes(tool)) {
      setActiveTools([...activeTools, tool])
    } else {
      setActiveTools(activeTools.filter((t) => {
        return  t !== tool
      }))
    }
  }

  if (ttsActive) {
    components.push(
      {elem: <Tts
        questionId={props.questionId}
        credentials={props.ttsOptions?.credentials}
        hasReferenceMaterial={props.ttsOptions?.hasReferenceMaterial || false}
        fetchReferenceMaterialHtml={props.ttsOptions?.fetchReferenceMaterialHtml || (() => document.createElement("span"))}
        externalQuestion={props.ttsOptions?.externalQuestion}
        assessmentItem={props.ttsOptions?.assessmentItem}
        outputBucket={props.ttsOptions?.outputBucket}
        close={() => toggleTool("tts")}
        dragContainer={props.dragContainer}
      />, key: "tts"}
    )
  }
  
  if (props.enabledTools.includes("highlighter") && props.highlighterOptions) {
    components.push(
      {elem: <Highlighter
        storagePrefix={props.highlighterOptions.storagePrefix}
        active={highlighterActive}
        highlighterRoots={props.highlighterOptions.highlighterRoots}/>, key: "highlighter"}
    )
  }

  if (notepadActive && props.notepadOptions) {
    components.push(
      {elem: <Notepad
        questionId={props.questionId}
        storagePrefix={props.notepadOptions.storagePrefix}
        close={() => toggleTool("notepad")}
        dragContainer={props.dragContainer}
        />, key: "notepad"}
    )
  }

  if(calculatorActive && props.calculatorOptions) {
    components.push({elem: <Calculator questionId={props.questionId} type={props.calculatorOptions.type} close={() => {toggleTool("calculator")}} dragContainer={props.dragContainer}/>, key: "calculator"})
  }


  /**
   * @type {{icon: string, title: string, code: string}[]}}
   */
  let embeds = [];

  if (props.embedOptions && props.enabledTools.includes("embed")) {
    props.embedOptions.codes.forEach((code) => {

      let embedIcon = file;
      let embedTitle = "Embedded Tool";

      let div = document.createElement("div");
      div.innerHTML = code;
      let iframe = div.querySelector("iframe");
      if (iframe) {
        let url = iframe.src
        let host = (new URL(url)).hostname
        if (host.includes("youtube") || host.includes("vimeo")) {
          embedTitle = "Video";
          embedIcon = fileVideo;
        } else if (host.includes("desmos")) {
          embedTitle = "Calculator/Math Tool";
          embedIcon = calculator;
        } else if (host.includes("google")) {
          embedTitle = "Document/File Reference";
          embedIcon = file;
        }

        let title = iframe.dataset.toolTitle;
        if (title && title !== "") {
          embedTitle = title;
        }
        embeds.push({ icon: embedIcon, title: embedTitle, code: code }); 
      }
    });
  }

  if(activeEmbeds.length > 0) {
    activeEmbeds.forEach((embed) => {
      let idx = parseInt(embed.split("_")[1]);
      if(props.embedOptions) {
        components.push({elem: <Embed close={() => {toggleTool(embed)}} dragContainer={props.dragContainer} code={props.embedOptions.codes[idx]}/>, key: embed})
      }
    })
  }


  return <>
    <div class="btn-group">
      <ToolButton title="Text To Speech" toolName="tts" icon={volume} enabledTools={props.enabledTools} active={ttsActive} toggleTool={toggleTool} />
      <ToolButton title="Highlighter" toolName="highlighter" icon={marker} enabledTools={props.enabledTools} active={highlighterActive} toggleTool={toggleTool} />
      <ToolButton title="Notes" toolName="notepad" icon={note} enabledTools={props.enabledTools} active={notepadActive} toggleTool={toggleTool} />
      <ToolButton title="Calculator" toolName="calculator" icon={calculator} enabledTools={props.enabledTools} active={calculatorActive} toggleTool={toggleTool} />
      {embeds.map((embed, index) => {
        return <ToolButton
          title={embed.title}
          // @ts-expect-error
          toolName={`embed_${index}`}
          icon={embed.icon}
          enabledTools={props.enabledTools.includes("embed") ? [`embed_${index}`] : []}
          // @ts-expect-error
          active={activeEmbeds.includes(`embed_${index}`)}
          toggleTool={toggleTool} />
      })}
    </div>
    {components.map((component) => {
      return <div key={component.key} className="tool-container">
        {component.elem}
      </div>
    })}
  </>
}

/**
 * 
 * @param {{
 *   enabledTools: string[],
 *   active: boolean,
 *   toolName: 'tts' | 'highlighter' | 'notepad' | 'calculator' | 'embed_0' | 'embed_1' | 'embed_2' | 'embed_3' | 'embed_4' ,
 *   title: string,
 *   toggleTool: (s: string) => void,
 *   icon: string,
 * }} props 
 */
function ToolButton(props) {
  if(!props.enabledTools.includes(props.toolName)) return null;
  return <button
    className={props.active ? "tool-menu-button active" : "tool-menu-button"}
    title={props.title}
    onClick={() => {
      props.toggleTool(props.toolName)
    }}
    dangerouslySetInnerHTML={{ __html: props.icon }}>
  </button>
}