
import EssayQuestion from "./models/essay-question.js"
import FillInTheBlankQuestion from "./models/fill-in-the-blank-question.js"
import DrawingQuestion from "./models/drawing-question.js"
import ScoreOnlyQuestion from "./models/score-only-question.js"
import MultipleChoiceQuestion from "./models/multiple-choice-question.js"
import OrderingQuestion from "./models/ordering-question.js"
import MatchingQuestion from "./models/matching-question.js"
import GriddedQuestion from "./models/gridded-question.js"
import GapMatchQuestion from "./models/gap-match-question.js"
import InlineChoiceQuestion from "./models/inline-choice-question.js";

const QUESTION_TYPE_MAP = {
  "essay": EssayQuestion,
  "equation": EssayQuestion,
  "fill-in-the-blank": FillInTheBlankQuestion,
  "drawing": DrawingQuestion,
  "score-only": ScoreOnlyQuestion,
  "single-bubble": MultipleChoiceQuestion,
  "multiple-bubble": MultipleChoiceQuestion,
  "ordering": OrderingQuestion,
  "matching": MatchingQuestion,
  "gridded": GriddedQuestion,
  "gap-match": GapMatchQuestion,
  "inline-choice": InlineChoiceQuestion
}

/**
 * 
 * @param {import("./shared-types").ItemJSON} json 
 * @returns {import("./models/item").default}
 */
export function fromJsonApi(json) {
    if(json.data.type == "assessment_items") {
      let id = json.data.relationships.question_version.data.id;
      let type = json.data.relationships.question_version.data.type;

      let questionVersion = json.included.find((inc) => {
        return inc.id == id && inc.type == type;
      })

      if(!questionVersion) {
        throw("Could not find question_version in json.includes")
      }

      let transformed = {
        data: questionVersion,
        included: json.included
      }

      return new QUESTION_TYPE_MAP[questionVersion.attributes.response_type](transformed);
    } else {
      return new QUESTION_TYPE_MAP[json.data.attributes.response_type](json);
    }
}