/**
 * 
 * @param {string} str 
 */
export function escapeStr (str){
  return str.replace(/[&<>'"]/g, tag => ({
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      "'": '&#39;',
      '"': '&quot;'
    }[tag])); 
};

/**
 * 
 * @param {string} str 
 */
export function unescapeStr (str){
  return str.replace(/&.{3,4};/g, (tag) => {
    console.log(tag)
    return {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&#39;': "'",
      '&quot;': '"'
    }[tag] || tag
  }); 
};
