import BaseComponent from "../base-component.js";
import DrawingEditor from "../../../drawing-editor/index.js"
import {h} from "preact"

class DrawingQuestionComponent extends BaseComponent {
  componentDidMount() {
    let response = this.props.studentResponse;

    if(response) {
      this.renderDrawingAnnotationEditor(response)
    }

    this.props.didRender();
  }

  componentWillUnmount() {
    if(!this._drawingEditor) {return};
    this._drawingEditor.destroy();
    this._drawingEditor = null;
  }


  /**
   * 
   * @param {import("../../shared-types").StudentResponse} response 
   */
  renderDrawingAnnotationEditor(response) {
    let meta = this.props.item.responseDeclaration.meta
    var drawingEditorContainer;
    if(this.base instanceof HTMLElement) drawingEditorContainer = this.base.querySelector(".drawing-editor-container")
    if(!drawingEditorContainer) {
      throw("Failed to find .drawing-editor-container")
    }

    this._drawingEditor = new DrawingEditor(drawingEditorContainer, {
      height: Number(meta.height),
      width: Number(meta.width),
      readOnly: this.props.readOnly,
      enableSaveWorkflow: this.props.mode == "teacher-facing",
      enableDrawingToggle: this.props.mode == "teacher-facing",
      onChange: this.props.teacherResponseDidChange,
      onSave: () => {
        var dataURL = this._drawingEditor.toDataURL();
        return this.props.teacherResponseDidFinishEdit({...this.props.teacherResponse, drawing_feedback: {
          url: dataURL
        }});
      }
    });

    this._drawingEditor.setBackgroundSrc(meta.drawing_src);

    let responseImage = response.drawing?.image_url || response.drawing?.image_url;

    if(responseImage) {
      this._drawingEditor.setReadOnlyLayerSrc(responseImage);
    }

    if(this.props.teacherResponse?.drawing_feedback?.url) {
      this._drawingEditor.fromDataURL(this.props.teacherResponse.drawing_feedback.url)
    }
  }

  responseCollector() {
    if(this.props.studentResponse) {
      return(
        h("div", {class: "drawing-editor-container"})
      )
    } else {
      return this.omittedPlaceholder();
    }
  }

  render() {
    return(
      this.wrapper(this.body(), this.responseCollector())
    );
  }
}

export default DrawingQuestionComponent
