import { h } from "preact";
import { useCallback, useEffect, useRef, useState } from "preact/hooks";

import interact, { makeNewRestrictedPosition } from "../../interact";
import { InteractEvent } from "@interactjs/types/index";

import { play, pause, backward, xMark } from "../../icons.js";
import { fromJsonApi } from "../../models.js";
import Question from "../../models/question.js";
import { unescapeStr } from "../../utils/xml.js";

/**
 *
 * @typedef {Object} Options
 * @property {Object | undefined} credentials,
 * @property {() => HTMLElement} fetchReferenceMaterialHtml,
 * @property {string | undefined} outputBucket, 
 * @property {boolean} hasReferenceMaterial,
 * @property {{
 *    fetchQuestionBodyHTML: () => HTMLElement,
 *    fetchChoiceContent: () => (string|HTMLElement)[]
 *    hasChoiceContent: boolean
 *  } | undefined} externalQuestion,
 *  @property {import("../../shared-types").ItemJSON | undefined} assessmentItem
 * }}
 */

/**
 *
 * @typedef {Options & {questionId: string, close: () => void, dragContainer?: string}} Props 
 * }}
 */

/**
 * @type import("../../../tts/index.js").default | null
 */
const initialTtsClientState = null;
const audioPlayer = new Audio();
window["_audioPlayer"] = audioPlayer;

/**
 *
 * @typedef {Object} SpeechMark
 * @property {string} type
  * @property {string} value
  * @property {number} time
*/

/**
 * @type {{url: string, marks: SpeechMark[]}[] | null}
 */
const initialSrcState = null;

/**
 * @type {{root: Element | null, roots: Element[] | null, startNode: Node | null, startNodeOffset: number | null} | null}
 */
const initialHtmlToHighlightState = null;


const finishedPlaybackEvent = new Event('finishedPlayback');

const EmptySrc = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

/**
 * @param {Props} props
 */
export default function (props) {
  let [ttsClient, setTtsClient] = useState(initialTtsClientState);
  let [playing, setPlaying] = useState(false);
  let [srcQueue, setSrcQueue] = useState(initialSrcState);
  let [sourceType, setSourceType] = useState(() => {
    if (documentHasSelection()) {
      return "selection"
    } else {
      return "prompt"
    }
  });

  let [needsNewSrcOnPlay, setNeedsNewSrcOnPlay] = useState(true);

  let [playbackSpeed, setPlaybackSpeed] = useState("medium");
  let [loading, setLoading] = useState(false);
  let [_model, setModel] = useState(props.assessmentItem ? fromJsonApi(props.assessmentItem) : null);
  let model = /** @type Question | null */ (_model);

  let [hasSelection, setHasSelection] = useState(documentHasSelection);
  let [htmlToHighlight, setHtmlToHighlight] = useState(initialHtmlToHighlightState);
  let sourceTypeSelect = useRef();

  let getQuestionBodyHTML = useCallback(() => {
    if (props.externalQuestion) {
      return props.externalQuestion.fetchQuestionBodyHTML();
    } else {
      if (model instanceof Question) {
        let elem = document.querySelector(".question-wrapper .question-body");
        if (elem) {
          return elem;
        }
        throw "Failed to find question body";
      } else {
        throw ("Expected assessment item or external question config")
      }
    }
  }, [props.questionId, model]);

  let getReferenceMaterialHTML = useCallback(() => {
    return props.fetchReferenceMaterialHtml();
  }, [props.questionId]);

  let getChoiceHtml = useCallback(() => {
    if (props.externalQuestion) {
      let choiceContent = props.externalQuestion.fetchChoiceContent();
      let ssml = "";
      /** @type Element[] */
      let roots = [];
      choiceContent.forEach((stringOrHtml) => {
        if (typeof stringOrHtml == "string") {
          ssml += stringOrHtml
        } else {
          roots.push(stringOrHtml);
          ssml += `<mark name='highlight_jump_${roots.length - 1}'/>${Question.htmlToSSML(stringOrHtml)}`;
        }
      })

      return { roots, ssml };
    } else {
      throw "Expected external question config"
    }
  }, [props.questionId, model]);

  const resetPlayer = () => {
    setSrcQueue(null);
    setPlaying(false);
    setSourceType("prompt");
    URL.revokeObjectURL(audioPlayer.src);
    audioPlayer.src = "";
  };

  const togglePlay = (e) => {
    e.preventDefault();
    if (playing) {
      audioPlayer.pause();
      setPlaying(false);
      return;
    }

    if (needsNewSrcOnPlay) {
      setSrcQueue(null);
      srcQueue = null;
    }

    if (srcQueue) {
      audioPlayer.play();
      setPlaying(true);
    } else {
      /**
       * @type {Element | null}
       */
      let html = null;

      /**
       * @type {Node | null}
       */
      let startNode = null;

      /**
       * @type {number | null}
       */
      let startNodeOffset = null;

      /**
       * @type {Element[] | null}
       */
      let roots = null;

      let ssml = "";
      if (sourceType == "prompt") {
        html = getQuestionBodyHTML();
        ssml = model ? model.promptSSML() : Question.htmlToSSML(html);
      } else if (sourceType == "reference-material") {
        html = getReferenceMaterialHTML();
        ssml = Question.htmlToSSML(html);
      } else if (sourceType == "answer-choices") {
        let choiceHtml = getChoiceHtml();
        ssml = choiceHtml.ssml;
        roots = choiceHtml.roots;
      } else if (sourceType == "selection") {
        let selection = document.getSelection();
        if (!selection || selection.rangeCount == 0) { return };

        let range = selection.getRangeAt(0);
        html = /** @type Element */ (range.commonAncestorContainer);
        startNode = range.startContainer;
        startNodeOffset = range.startOffset;

        let contents = range.cloneContents();
        var div = document.createElement('div');
        div.appendChild(contents);

        //if the selection is for the question body we should apply additional processing
        if (range.commonAncestorContainer instanceof HTMLElement &&
          range.commonAncestorContainer.closest(".question-body") &&
          model instanceof Question) {

          model.processPromptMarkupForTts(div)
        }

        ssml = Question.htmlToSSML(div);
        selection.removeAllRanges();
      }

      if (!html && !roots) {
        return
      }

      setHtmlToHighlight({ root: html, roots, startNode, startNodeOffset });

      if (ttsClient) {
        setLoading(true);
      }

      audioPlayer.src = EmptySrc;
      audioPlayer.play().catch((e) => { console.error(e) });

      ttsClient?.synthesizeSpeech(ssml, playbackSpeed).then((urls) => {
        setNeedsNewSrcOnPlay(false);
        setLoading(false);
        urls = urls.map((url) => {
          return { ...url, marks: filterMarks(url.marks) }
        });
        setSrcQueue(urls);
        audioPlayer.src = urls[0].url;
        audioPlayer.play().catch((e) => { console.error(e) });
        setPlaying(true);
      }, () => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    let position = {x: 0, y: 0};
    import("../../../tts/index.js").then(({ default: client }) => {
      let clientInstance = new client({
        credentials: props.credentials,
        outputBucket: props.outputBucket,
      });
      setTtsClient(clientInstance);
    });

    interact(".tts-floating-window").draggable({
      inertia: true,
      allowFrom: ".drag-handle",
      autoScroll: {
        enabled: true
      },
      listeners: {
        move: (evt) => {
          position = makeNewRestrictedPosition(evt, position);
          if (evt.target) {
            evt.target.style.transform = `translate(${position.x}px, ${position.y}px)`
          }
        },
      }
    });

    return function cleanup() {
      resetPlayer();
    };
  }, []);

  //reset audioPlayer/model when the question id changes
  useEffect(() => {
    setModel(props.assessmentItem ? fromJsonApi(props.assessmentItem) : null)
    if (srcQueue) {
      resetPlayer();
    }
  }, [props.questionId]);

  useEffect(() => {
    let callback = (event) => {
      let selection = document.getSelection();
      let hasSelection = selection !== null && !selection.isCollapsed;
      setHasSelection(hasSelection)
      if (hasSelection) {
        setSourceType("selection")
        setNeedsNewSrcOnPlay(true);
      } else if (!hasSelection && sourceTypeSelect.current.value == "selection") {
        setSourceType("prompt")
        setNeedsNewSrcOnPlay(true);
      }
    };

    document.addEventListener("selectionchange", callback);
    return function cleanup() {
      document.removeEventListener("selectionchange", callback);
    }
  }, []);

  useEffect(() => {
    function endedCallback() {
      if (srcQueue) {
        let currentSrcIndex = srcQueue.findIndex((src) => src.url == audioPlayer.src);
        if (currentSrcIndex == srcQueue.length - 1) {
          audioPlayer.dispatchEvent(finishedPlaybackEvent);
          audioPlayer.pause();
          setPlaying(false);
        } else {
          audioPlayer.src = srcQueue[currentSrcIndex + 1].url;
          audioPlayer.load();
          audioPlayer.play();
        }
      }
    }

    audioPlayer.addEventListener("ended", endedCallback);

    return function cleanup() {
      audioPlayer.removeEventListener("ended", endedCallback);
    }
  }, [srcQueue])

  useEffect(() => {
    if (!htmlToHighlight) return;

    let marker = document.createElement("span");
    marker.classList.add("tts-highlight");

    /**
     * 
     * @param {Node | undefined} currentNode 
     * @returns 
     */
    function buildWalker(currentNode = undefined) {
      if (!htmlToHighlight) return;
      let initialRoot = htmlToHighlight.root || (htmlToHighlight.roots ? htmlToHighlight.roots[0] : null);
      if (!initialRoot) return;
      let walker = document.createTreeWalker(initialRoot, NodeFilter.SHOW_TEXT + NodeFilter.SHOW_ELEMENT, {
        acceptNode(node) {
          if (node instanceof HTMLElement) {
            if (['video', 'audio', 'iframe', 'title', 'style', 'script'].includes(node.tagName.toLowerCase())) {
              return NodeFilter.FILTER_REJECT
            }

            if (node.classList.contains("drawing-editor")) {
              return NodeFilter.FILTER_REJECT
            }

            return NodeFilter.FILTER_ACCEPT
          } else {
            return NodeFilter.FILTER_ACCEPT
          };
        }
      });

      if (htmlToHighlight.startNode) {
        if (htmlToHighlight.startNodeOffset && htmlToHighlight.startNodeOffset > 0) {
          if (htmlToHighlight.startNode instanceof Text) {
            let nextNode = htmlToHighlight.startNode.splitText(htmlToHighlight.startNodeOffset);
            walker.currentNode = nextNode;
          } else {
            walker.currentNode = htmlToHighlight.startNode.childNodes[htmlToHighlight.startNodeOffset];
          }
        } else {
          walker.currentNode = htmlToHighlight.startNode;
        }
      }

      if (currentNode) {
        walker.currentNode = currentNode;
      }

      return walker;
    }

    let maybeWalker = buildWalker();
    if (!maybeWalker) return;

    let walker = maybeWalker;

    let lastMark = null;
    let cancel = false;

    function clearMarker() {
      let maybeParent = marker.parentElement;
      if (maybeParent) {
        let parent = maybeParent;
        marker.childNodes.forEach((node) => {
          parent.insertBefore(node, marker);
        })

        parent.removeChild(marker);
      }
    }

    /**
     * 
     * @param {SpeechMark} instructionMark 
     */
    function processInstructionMark(instructionMark) {
      if (instructionMark.value.startsWith("highlight_jump") && htmlToHighlight && htmlToHighlight.roots && htmlToHighlight.roots.length > 0) {
        let jumpIndex = parseInt(instructionMark.value.split("_")[2]);
        walker = document.createTreeWalker(htmlToHighlight.roots[jumpIndex], NodeFilter.SHOW_TEXT + NodeFilter.SHOW_ELEMENT, null);
      }
    }

    let lastTime = 0;

    function renderHighlight() {
      let currentSrc = srcQueue?.find((src) => { return src.url == audioPlayer.src });
      if (cancel || !currentSrc) {
        return;
      }

      let currentTime = (audioPlayer.currentTime * 1000);
      if (currentTime <= lastTime && !audioPlayer.paused) {
        requestAnimationFrame(renderHighlight);
        return;
      }

      lastTime = currentTime;

      let closestMark = null;
      /** @type SpeechMark | null*/
      let instructionMark = null;
      for (let mark of currentSrc.marks ?? []) {
        if (mark.time > currentTime) {
          if (mark.type == "ssml") {
            instructionMark = mark;
            continue;
          } else {
            break;
          }
        }

        if (mark.type != "ssml") {
          closestMark = mark;
        }
      }

      if (instructionMark) {
        if (closestMark && closestMark.time > instructionMark.time) {
          processInstructionMark(instructionMark);
          instructionMark = null
        } else if (!closestMark) {
          processInstructionMark(instructionMark);
          instructionMark = null
        }
      }

      if (closestMark) {
        if (closestMark != lastMark) {
          clearMarker()

          /** @type Node | null */
          let currentNode = walker.currentNode;
          let originalNode = currentNode;
          while (currentNode) {
            if (!currentNode) {
              break;
            }

            if (currentNode.nodeType == Node.TEXT_NODE) {
              let textNode = /** @type {Text} */ (currentNode);

              let textToFind = unescapeStr(closestMark.value);
              let idx = textNode.textContent?.indexOf(textToFind);
              if (idx !== undefined && idx >= 0) {
                let textNodeToHighlight = textNode.splitText(idx);
                let nextTextNode = textNodeToHighlight.splitText(textToFind.length);
                marker.appendChild(textNodeToHighlight);
                textNode.parentNode?.insertBefore(marker, nextTextNode);
                walker.currentNode = nextTextNode;
                lastMark = closestMark;
                break;
              }
            }

            currentNode = walker.nextNode();
            if (!currentNode) {
              lastMark = closestMark;
              let maybeWalker = buildWalker(originalNode);
              if (!maybeWalker) return;
              walker = maybeWalker;

              break
            };
          }
        }
      }

      if (instructionMark) {
        processInstructionMark(instructionMark);
      }

      if (!audioPlayer.paused) {
        requestAnimationFrame(renderHighlight);
      }
    }

    let playCallback = () => {
      lastTime = 0;
      requestAnimationFrame(renderHighlight);
    };

    //When playback has finished make a new walker
    let finishedPlaybackCallback = () => {
      lastTime = 0;
      clearMarker();
      let maybeWalker = buildWalker();
      if (!maybeWalker) return;
      walker = maybeWalker;
    };

    if (!audioPlayer.paused) {
      requestAnimationFrame(renderHighlight);
    }
    audioPlayer.addEventListener("play", playCallback);
    audioPlayer.addEventListener("finishedPlayback", finishedPlaybackCallback);

    return () => {
      clearMarker();
      audioPlayer.removeEventListener("play", playCallback);
      audioPlayer.removeEventListener("finishedPlayback", finishedPlaybackCallback);
      cancel = true
    }
  }, [htmlToHighlight, srcQueue]);

  return (
    <div class="tts-floating-window">
      <div class="drag-handle tts-floating-window-header">
        <div class="tts-floating-window-title">Text to Speech</div>
        <span class="tts-floating-window-exit" dangerouslySetInnerHTML={{ __html: xMark }} onClick={props.close}></span>
      </div>
      <div class="controls">
        <span
          className={loading ? "play item-player-loading" : "play"}
          dangerouslySetInnerHTML={{
            __html: loading ? "" : playing ? pause : play,
          }}
          onMouseDown={(e) => { e.preventDefault() }}
          onTouchStart={(e) => { e.preventDefault() }}
          onClick={togglePlay}
          onTouchEnd={togglePlay}
        ></span>

        <span
          className="rewind"
          dangerouslySetInnerHTML={{ __html: backward }}
          onClick={(e) => {
            e.preventDefault()
            audioPlayer.dispatchEvent(finishedPlaybackEvent);
            audioPlayer.currentTime = 0;
          }}
        ></span>
        <div className="playback-speed">
          Speed:
          <select
            value={playbackSpeed}
            onChange={(event) => {
              setPlaybackSpeed(event.currentTarget.value);
              setSrcQueue(null);
              if (playing) {
                togglePlay(event);
              }
            }}
          >
            <option value="slow">Slow</option>
            <option value="medium">Normal</option>
            <option value="fast">Fast</option>
          </select>
        </div>

        <div className="type-select">
          Play:
          <select
            value={sourceType}
            ref={sourceTypeSelect}
            onChange={(event) => {
              resetPlayer();
              let value = event.currentTarget.value;
              setSourceType(value);
              setNeedsNewSrcOnPlay(true);
              let selection = document.getSelection();
              if (selection) { selection.removeAllRanges() };
            }}
          >


            {props.questionId ? <option value="prompt">Question</option> : null}
            {(props.externalQuestion?.hasChoiceContent || model && model instanceof Question && model.hasChoiceContentForTts()) ? (
              <option value="answer-choices">Answer Choices</option>
            ) : null}
            {props.hasReferenceMaterial ? (
              <option value="reference-material">Passage</option>
            ) : null}
            <option value="selection" disabled={!hasSelection}>Selected Text</option> :
          </select>
        </div>
      </div>
    </div>
  );
}

function documentHasSelection() {
  let selection = document.getSelection();
  return !!(selection && !selection.isCollapsed && selection.rangeCount > 0)
}

/**
 *
 * @param {HTMLElement | undefined} container 
 */
function dragMoveListener(container) {
  /**
   * 
   * @param {InteractEvent} event 
   */
  return function (event) {
    var target = event.target;

    var offset = 0;
    if (container) {
      var originalScrollTop = target.getAttribute("data-scroll-top")
      if (!originalScrollTop) {
        originalScrollTop = String(container.scrollTop);
        target.setAttribute("data-scroll-top", originalScrollTop)
      }

      var currentScrollTop = container.scrollTop;
      offset = parseFloat(originalScrollTop) - currentScrollTop;
    }

    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute("data-x") + "") || 0) + event.dx;
    var y = (parseFloat(target.getAttribute("data-y") + "") || 0) + event.dy;

    // translate the element
    target.style.transform = "translate(" + x + "px, " + (y - offset) + "px)";

    // update the posiion attributes
    target.setAttribute("data-x", String(x));
    target.setAttribute("data-y", String(y));
  };
};

/**
 * 
 * @param {SpeechMark[]} marks 
 */
function filterMarks(marks) {
  let currentlyIgnoring = false;

  return marks.filter((mark) => {
    if (mark.type == "ssml") {
      if (mark.value == "highlight_ignore_start") {
        currentlyIgnoring = true;
        return false
      } else if (mark.value == "highlight_ignore_end") {
        currentlyIgnoring = false;
        return false
      }
      return true;
    }

    return !currentlyIgnoring
  })
}
