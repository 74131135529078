import GapMatchComponent from "../gap-match-component.jsx";
import { h, render } from "preact";
import copy from "fast-copy";
//@ts-ignore
export default class GapMatchReportComponent extends GapMatchComponent {

  componentDidMount() {
    this.props.didRender();
    if (!(this.base instanceof HTMLElement)) {
      throw ("expected base to be an html element");
    }

    let studentResponseChoices = [];
    this.base.querySelectorAll(".gap-match").forEach(
      /**
       * @param {HTMLSpanElement} gapMatchElement
       */
      (gapMatchElement) => {
        if (gapMatchElement instanceof HTMLSpanElement) {

          var container = document.createElement('SPAN');
          gapMatchElement.replaceWith(container);

          const gapId = gapMatchElement.dataset.gapIdentifier || "";

          let correctResponseGapMatches = this.correctResponseGapMatches(gapId, true);
          if (correctResponseGapMatches) {
            let choice = null;
            let studentResponseGapMatch = null;

            let gapMatch = this.gapMatch(gapId);
            if (gapMatch) {
              choice = this.choice(gapMatch.choice_id);
              studentResponseGapMatch = this.correctResponseGapMatch(gapId, gapMatch.choice_id);
              studentResponseChoices.push(gapMatch.choice_id);
            }

            render(this.gapResponse(choice, correctResponseGapMatches, studentResponseGapMatch), container);
          }
          else {
            render(this.noAnswer(), container);
          }
        }
      });

    let responseCollector = this.base.querySelector(".response-collector");
    if (responseCollector instanceof HTMLDivElement) {
      render(this.responseCollector(studentResponseChoices), responseCollector);
    }
  }

  responseCollector(studentResponseChoices) {
    return (
      <div className="gap-match-choice-container">
        {this.props.item.attributes.response_declaration.choices
          ?.sort((a, b) => a.ordinal - b.ordinal)
          ?.map((choice) => {
            if (!studentResponseChoices.includes(choice.id)) {
              return (
                <div className="static-choice">
                  <div class="gap-match-choice-content" dangerouslySetInnerHTML={{ __html: choice.body }}></div>
                </div>
              )
            }
            else {
              return ('')
            };
          }) || []}
      </div>
    );
  }

  render() {
    return this.wrapper(this.body(), (<div class="response-collector"></div>));
  }
}
