import GapMatchComponent from "./gap-match-component.jsx";
import { h } from "preact";
import copy from "fast-copy";
//@ts-ignore
export default class InlineChoiceComponent extends GapMatchComponent {
  constructor(props) {
    super(props);
    this.hidePoints = props.hidePoints;
    this.hideCorrectAnswers = props.hideCorrectAnswers;
    this.studentResponse = copy(this.props.studentResponse) || {
      inline_choice: {
        inline_choice_matches: [],
      },
    };
    this.correctResponse = copy(this.props.item.correctResponse) || {
      max_score: 0,
      scoring_mode: "",
      definition: [{
        gap_matches: [],
      }],
    };
    this.scoringData = copy(this.props.scoringData) || {
      max_score: 0,
      scoring_mode: "",
      definition: [{
        gap_matches: [],
      }],
    };
  }

  inlineChoiceMatch(gapId) {
    return this.studentResponse.inline_choice?.inline_choice_matches.find(
      (inlineChoiceMatch) => {
        return (
          String(inlineChoiceMatch.gap_id) == gapId
        );
      }
    );
  }
}
