import Question from "./question.js"
import MatchingQuestionComponent from "../components/questions/matching-question.jsx"
import MatchingReportComponent from "../components/reports/matching-report.jsx"
import MatchingPreviewComponent from "../components/previews/matching-preview.jsx";
class MatchingQuestion extends Question {
  component(mode) {
    switch(mode) {
      case "student-facing-report":
        return MatchingReportComponent;
      case "preview":
        return MatchingPreviewComponent;
      default:
      return MatchingQuestionComponent;
    }
  }
}

export default MatchingQuestion;