import BaseComponent from "../base-component.js";
import { h } from "preact";
import copy from "fast-copy";
import interact from "../../interact";
import {Interactable, InteractEvent} from "@interactjs/types";
//@ts-ignore
import handle from "../../../assets/four-way-arrow.png";
export default class GapMatchQuestionComponent extends BaseComponent {
  constructor(props) {
    super(props);
    /**
     * @type {import("src/shared-types.js").StudentResponse}
     */
    this.studentResponse = {
      gap_match: {
        gap_match_matches: copy(this.props.studentResponse?.gap_match?.gap_match_matches) || [],
      },
    };
  }

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    this.props.didRender();
    var _choiceContainer;
    if(this.base instanceof HTMLElement) {
      _choiceContainer = this.base.querySelector(".gap-match-choice-container");
      if(_choiceContainer instanceof HTMLElement) {
        var choiceContainer = _choiceContainer;
      } else {
        throw("Failed to find .gap-match-choice-container");
      }
    } else {
      throw("expected base to be an html element");
    }
    this.base.querySelectorAll(".gap-match").forEach(
      /**
       * @param {HTMLSpanElement} gapMatchElement
       */
      (gapMatchElement) => {
        gapMatchElement.innerHTML = "";
        if (gapMatchElement instanceof HTMLSpanElement) {
          let res = this.studentResponse.gap_match?.gap_match_matches.find(
            (m) => {
              return (
                String(m.gap_id) == gapMatchElement.dataset.gapIdentifier
              );
            }
          );
          if (res) {
            let elem = choiceContainer.querySelector(
              `#gap-match-choice-${res.choice_id}`
            );
            if (elem) {
              gapMatchElement.appendChild(elem);
            }
          }
        }
      });

    /**
     * @type Interactable[]
     */
    let interactions = [];
    var n = interactions.push(interact(".draggable-choice"))
    interact.dynamicDrop(true)
    /**
     * @type HTMLElement | null
     */
    var container = null;
    if(this.props.dragScrollContainer) {
      container = document.querySelector(this.props.dragScrollContainer)
    }

    interactions[n-1].draggable({
      // enable inertial throwing
      inertia: true,
      // enable autoScroll
      autoScroll: {
        container: container || undefined,
        enabled: true
      },
      max: 1,

      listeners: {
        start: function(event) {
          event.target.style.opacity = "0.75"
        },
        // call this function on every dragmove event
        move: dragMoveListener(container || undefined),

        // call this function on every dragend event
        end(event) {
          event.target.style.opacity = "1"
          if(!event.dropzone) {
            event.target.style.transform = "";
            event.target.removeAttribute("data-x");
            event.target.removeAttribute("data-y");
            event.target.removeAttribute("data-scroll-top");
          }
        },
      },
    });
    
    n = interactions.push(interact('.gap-match'));
    interactions[n-1].dropzone({
      // only accept elements matching this CSS selector
      accept: '.draggable-choice',
      // listen for drop related events:
      ondropactivate: function (event) {
        // add active dropzone feedback
        event.target.classList.add('drop-active')
      },
      ondragenter: function (event) {
        event.target.classList.add('gap-match-drag-over')
        event.relatedTarget.classList.add("can-drop")
      },
      ondragleave: function (event) {
        event.target.classList.remove('gap-match-drag-over')
        event.relatedTarget.classList.remove("can-drop")
      },
      /**
       * 
       * @param {InteractEvent} event 
       */
      ondrop: (event) => {
        if(!(event.relatedTarget instanceof HTMLElement)) {return;}
        let existingGap = event.relatedTarget.closest(".gap-match");
        if(existingGap instanceof HTMLElement) {
          this.removeMatch(Number(event.relatedTarget.dataset.id));
        }

        let existingChoiceElem = event.target.querySelector(".draggable-choice")
        event.target.classList.remove('gap-match-drag-over')
        event.relatedTarget.classList.remove("can-drop")
        event.target.appendChild(event.relatedTarget);
        event.relatedTarget.style.transform = "";
        event.relatedTarget.removeAttribute("data-x");
        event.relatedTarget.removeAttribute("data-y");
        event.relatedTarget.removeAttribute("data-scroll-top");

        if(existingChoiceElem instanceof HTMLElement) {
          choiceContainer.appendChild(existingChoiceElem);
          this.props.item.attributes.response_declaration.choices
            ?.sort((a, b) => a.ordinal - b.ordinal)
            .forEach((choice) => {
              let choiceElem = choiceContainer.querySelector(`#gap-match-choice-${choice.id}`);
              if (choiceElem) {
                choiceContainer.appendChild(choiceElem);
              }
            });

          this.removeMatch(Number(existingChoiceElem.dataset.id));
        }

        this.addMatch(
          Number(event.relatedTarget.dataset.id),
          Number(event.target.dataset.gapIdentifier)
        );
      },
      ondropdeactivate: function (event) {
        event.target.classList.remove('gap-match-drag-over')
      }
    })

    n = interactions.push(interact(choiceContainer))
    interactions[n-1].dropzone({
      accept: ".draggable-choice",
      ondrop: (event) => {
        event.target.appendChild(event.relatedTarget);
        event.relatedTarget.style.transform = "";
        event.relatedTarget.removeAttribute("data-x");
        event.relatedTarget.removeAttribute("data-y");
        this.props.item.attributes.response_declaration.choices
          ?.sort((a, b) => a.ordinal - b.ordinal)
          .forEach((choice) => {
            let choiceElem = choiceContainer.querySelector(`#gap-match-choice-${choice.id}`);
            if (choiceElem) {
              choiceContainer.appendChild(choiceElem);
            }
          });

        this.removeMatch(Number(event.relatedTarget.dataset.id))
      }
    })

    this.interactions = interactions;
  }

  componentWillUnmount() {
    if(this.interactions) {
      this.interactions.forEach((i) => {i.unset()});
    }
  }

  responseCollector() {
    return (
      <div class="response-collector">
        <div
          className="gap-match-choice-container"
        >
          <button
            class="gap-match-clear"
            onClick={() => {
              this.clearResponse();
            }}
          >
            Clear Response
          </button>
          {this.props.item.attributes.response_declaration.choices
            ?.sort((a, b) => a.ordinal - b.ordinal)
            ?.map((choice) => {
              return (
                <div
                  key={choice.id}
                  id={`gap-match-choice-${choice.id}`}
                  data-id={choice.id}
                  className="draggable-choice"
                >
                  <img src={handle} class="gap-match-choice-handle"/>
                  <div class="gap-match-choice-content" dangerouslySetInnerHTML={{ __html: choice.body }}></div>
                </div>
              );
            }) || []}
        </div>
      </div>
    );
  }

  render() {
    return this.wrapper(this.body(), this.responseCollector());
  }

  /**
   *
   * @param {number} choiceId
   * @param {number} gapId
   */
  addMatch(choiceId, gapId) {
    this.studentResponse = {
      gap_match: {
        gap_match_matches: [
          ...(this.studentResponse.gap_match?.gap_match_matches || []).filter((match) => {
            return match.gap_id !== gapId
          }),
          {
            gap_id: gapId,
            choice_id: choiceId,
          },
        ],
      },
    };
    this.props.responseDidChange(this.studentResponse);
  }

  /**
   *
   * @param {number} choiceId
   */
  removeMatch(choiceId) {
    if (!this.studentResponse.gap_match) {
      return;
    }

    this.studentResponse = {
      gap_match: {
        gap_match_matches: this.studentResponse.gap_match.gap_match_matches.filter(
          (match) => {
            return match.choice_id !== choiceId;
          }
        ),
      },
    };
    this.props.responseDidChange(this.studentResponse);
  }

  clearResponse() {
    if (!this.base || !(this.base instanceof HTMLElement)) {
      return;
    }
    if (
      !this.studentResponse.gap_match ||
      this.studentResponse.gap_match.gap_match_matches.length == 0
    ) {
      return;
    }
    let base = this.base;
    let container = base.querySelector(".gap-match-choice-container");

    this.props.item.attributes.response_declaration.choices
      ?.sort((a, b) => a.ordinal - b.ordinal)
      .forEach((choice) => {
        let choiceElem = base.querySelector(`#gap-match-choice-${choice.id}`);
        if (choiceElem) {
          container?.appendChild(choiceElem);
        }
      });

    this.studentResponse = {
      gap_match: {
        gap_match_matches: [],
      },
    };

    this.props.responseDidChange(this.studentResponse);
  }
}
/**
 *
 * @param {HTMLElement | undefined} container 
 */
 function dragMoveListener(container) {
  /**
   * 
   * @param {InteractEvent} event 
   */
  return function(event){
    var target = event.target;

    var offset = 0;
    if(container) {
      var originalScrollTop = target.getAttribute("data-scroll-top")
      if(!originalScrollTop) {
        originalScrollTop = String(container.scrollTop);
        target.setAttribute("data-scroll-top", originalScrollTop)
      }

      var currentScrollTop = container.scrollTop;
      offset = parseFloat(originalScrollTop) - currentScrollTop;
    }

    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute("data-x") + "") || 0) + event.dx;
    var y = (parseFloat(target.getAttribute("data-y") + "") || 0 ) + event.dy ;

    // translate the element
    target.style.transform = "translate(" + x + "px, " + (y - offset) + "px)";

    // update the posiion attributes
    target.setAttribute("data-x", String(x));
    target.setAttribute("data-y", String(y));
  };
};
