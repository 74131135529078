import {
  h,
  Component
} from 'preact';

/**
 * @typedef {object} Props
 * @prop {import("../models/question").default} item
 * @prop {import("../shared-types").StudentResponse} [studentResponse]
 * @prop {import("../shared-types").TeacherResponse | undefined} teacherResponse
 * @prop {import("../shared-types").ScoringData | undefined} scoringData 
 * @prop {function} didRender
 * @prop {boolean} readOnly
 * @prop {function} teacherResponseDidChange
 * @prop {function} teacherResponseDidFinishEdit
 * @prop {function(import("../shared-types").StudentResponse): void} responseDidChange
 * @prop {'teacher-facing' | 'student-facing' | 'preview' | 'student-facing-report'} mode
 * @prop {boolean | undefined} hideCorrectAnswers
 * @prop {boolean | undefined} hidePoints 
 * @prop {string | undefined} dragScrollContainer 
 * @prop {any[]} [bodyDiff] 

 *
 * @extends {Component<Props>}
 */
class BaseComponent extends Component {
  componentDidMount() {
    this.props.didRender();
  }

  omittedPlaceholder() {
    return h("div", {
      class: "response-collector student-omitted"
    }, "No response submitted");
  }

  wrapper() {
    return h("div", {
      class: "question-wrapper"
    }, ...arguments)
  }

  body() {
    return h("div", {
      class: "question-body",
      dangerouslySetInnerHTML: {
        __html: this.props.item.bodyWithSubstitutions || this.props.item.body
      }
    })
  }

  render() {
    return h("p", {}, "component missing for item");
  }

  round(float) {
    var parts = float.toString().split('.');
    if (parts.length > 1) {
      var decimal = parseFloat(parts[0] + '.' + parts[1]).toFixed(Math.min(2, parts[1].length));
      float = parseFloat(decimal.toString().replace(/0+$/gm, ""));
    }
    return float;
  }
}

export default BaseComponent;