import BaseComponent from "../base-component.js";
import {h} from "preact"

class MultipleChoiceQuestionComponent extends BaseComponent {

  responseCollector() {
    let choices = this.props.item.responseDeclaration.choices
    if(choices) {
      let sortedChoices = choices.sort((a, b) => { return a.ordinal > b.ordinal ? 1 : -1})
      return h("div", {class: "response-collector"}, sortedChoices.map((choice) => {
        return h("div",{
          class: "response"
        }, [
          h("div", {class: "label"}, h("button", {tabIndex: 1, class: "bubble"}, choice.identifier)),
          h("div", {class: "answer-container"},
            h("div", {class: "answer", dangerouslySetInnerHTML: {__html: choice.body}}))
        ]);
      }))
    }
  }

  render() {
    return(
      this.wrapper(
        this.body(), this.responseCollector()
      )
    );
  }
}

export default MultipleChoiceQuestionComponent 