import Item from "./item.js"
import {escapeStr} from "../utils/xml.js";
class Question extends Item {
  /**
   * @param item {import("../shared-types").ItemJSON}
   */

  constructor(item) {
    super(item);
    this.responseType = this.attributes.response_type;
    this.responseDeclaration = this.attributes.response_declaration;
    this.correctResponse = this.attributes.correct_response;
  }

  promptSSML() {
    let parser = new DOMParser();
    let body = parser.parseFromString(this.bodyWithSubstitutions || this.body, "text/html").body;
    return Question.htmlToSSML(this.processPromptMarkupForTts(body))
  }

  /**
   * 
   * @param {Element} elem 
   */
  processPromptMarkupForTts(elem) {
    return elem
  }

  choiceTextForTts() {
    return "";
  }

  hasChoiceContentForTts() {
    return false
  }

  /**
   * 
   * @param {Element} rootNode 
   * @returns {string}
   */
  static htmlToSSML(rootNode) {
    let handleNode = (node) => {
      if (!(node instanceof Element)) {
        if (node.nodeType !== node.COMMENT_NODE) {
          let txt = escapeStr(node.textContent);
          return (txt + " ").replace(/\s\s+/g, ' ');
        } else {
          return '';
        } 
      }
    
      if (['video', 'audio', 'iframe', 'title', 'style', 'script'].includes(node.tagName.toLowerCase())) {
        return '';
      }

      if(['break'].includes(node.tagName.toLocaleLowerCase())) {
        return node.outerHTML
      }

      if(node.classList.contains("drawing-editor")) {
        return '';
      }

      if (node.tagName.toLowerCase()==='img') {
        const alt = node.getAttribute('alt');
        if (alt) {
          return '<mark name="highlight_ignore_start"/><emphasis level="reduced">'+escapeStr(alt)+'</emphasis><mark name="highlight_ignore_end"/>'
        } else {
          return ''
        }
      }

      let ssmlTagName = '';
      switch (node.tagName.toLowerCase()) {
        case 'b': ssmlTagName = '<emphasis>'; break;
        case 'h1': ssmlTagName = '<emphasis>'; break;
        case 'h2': ssmlTagName = '<emphasis>'; break;
        case 'h3': ssmlTagName = '<emphasis>'; break;
        case 'h4': ssmlTagName = '<emphasis>'; break;
        case 'h5': ssmlTagName = '<emphasis>'; break;
        case 'h6': ssmlTagName = '<emphasis>'; break;
        case 'em': ssmlTagName = '<emphasis>'; break;
        case 'p': ssmlTagName = '<p>'; break;
        default: break;
      }
    
      return ssmlTagName + (node.hasChildNodes() ? Array.from(node.childNodes).reduce((acc, node) => {
        return acc + handleNode(node);
      },'') : '' ) + (ssmlTagName ? ssmlTagName.replace('<','</'): '');
    }

    return handleNode(rootNode)
  }

}

export default Question;