import {h} from "preact";
import { useEffect, useRef } from "preact/hooks";
import { xMark } from "../../icons";
import interact, { makeNewRestrictedPosition } from "../../interact";

/**
 * @typedef {Object} Options
 * @property {string[]} codes
 */

/**
 * @typedef {{code: string} & {close: () => void}} Props 
 */

/**
 * @param {Props} props
 */
export default function Embed(props) {
    /**
     * @type {import("preact").RefObject.<HTMLDivElement>}
     */
    let floatingWindowRef = useRef(null);

    useEffect(() => {
        let position = {x: 0, y: 0};
        let iframe = floatingWindowRef.current?.querySelector("iframe");
        if(!floatingWindowRef.current) {return};
        let header = floatingWindowRef.current.querySelector(".floating-window-header");
        if(!header || !(header instanceof HTMLElement)) {return};

        interact(header).draggable({
            max: 1,
            autoScroll: {
                enabled: true
            },
            listeners: {
                /**
                 * 
                 * @param {import("@interactjs/types").InteractEvent} evt 
                 */
                move: (evt) => {
                    position = makeNewRestrictedPosition(evt, position);
                    if (floatingWindowRef.current) {
                        floatingWindowRef.current.style.transform = `translate(${position.x}px, ${position.y}px)`
                    }
                },
            }
        })
        
        interact(floatingWindowRef.current).resizable({
            edges: { left: true, right: true, bottom: true, top: true },
            modifiers: [interact.modifiers.aspectRatio({
                ratio: "preserve"
            })],
            listeners: {
                move(event) {
                    position.x += event.deltaRect.left;
                    position.y += event.deltaRect.top;

                    if(iframe && floatingWindowRef.current) {
                        iframe.style.width = event.rect.width + "px";
                        iframe.style.height = event.rect.height + "px";
                        floatingWindowRef.current.style.webkitTransform = floatingWindowRef.current.style.transform =
                            "translate(" + position.x + "px," + position.y + "px)";
                    }
                },

                start(event) {
                    if(iframe) {
                        iframe.style.pointerEvents = "none";
                    }
                },

                end(evnet) {
                    if(iframe) {
                        iframe.style.pointerEvents = "auto";
                    }
                }
                
            }
        });

        if(iframe) {
            let title = iframe.dataset.toolTitle;

            if(title) {
                let header = floatingWindowRef.current?.querySelector(".floating-window-header-title");
                if(header) {
                    header.textContent = title;
                }
            }
        }
    }, [props.code]);

    return <div class="embed-floating-window floating-window" ref={floatingWindowRef}>
        <div class="floating-window-header drag-handle">
            <div class="floating-window-header-title">
            </div>
            <div class="floating-window-header-exit" dangerouslySetInnerHTML={{__html: xMark}} onClick={props.close}></div>
        </div>
        <div class="embed-container" dangerouslySetInnerHTML={{__html: props.code}}>
        </div>
    </div>
};
