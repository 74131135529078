import BaseComponent from "../base-component.js";
import {h} from "preact"
import EssayAnnotationEditor from "./essay-annotation-editor.jsx";

class EssayQuestionComponent extends BaseComponent {
  responseCollector() {
    var value = "";
    if(this.props.studentResponse) {
      value = this.props.studentResponse.essay?.response || "";
    } else {
      return this.omittedPlaceholder();
    }

    if(this.props.mode == "teacher-facing" || this.props.mode == "student-facing-report") {
      return (
        <EssayAnnotationEditor
          response={value}
          teacherResponse={this.props.teacherResponse}
          teacherResponseDidChange={this.props.teacherResponseDidChange}
          teacherResponseDidFinishEdit={this.props.teacherResponseDidFinishEdit}
          readOnly={this.props.mode == "student-facing-report"}
          />
      )
    } else {
      if(this.props.readOnly) {
        return h("div", {class: "response-collector"},
          h("div", {
            class: "essay-response",
            style: "border: solid 1px gray; white-space: pre-line;",
            dangerouslySetInnerHTML: {__html: value}
          })
        );
      } else {
        return h("div", {class: "response-collector"},
          h("textarea", {
            class: "essay-response",
            value: value
          })
        );
      }
    }
  }

  render() {
    return(
      this.wrapper(
        this.body(),
        this.responseCollector()
      )
    );
  }
}

export default EssayQuestionComponent
