import BaseComponent from "../base-component.js";
import { h } from "preact";
import interact from "../../interact";
import { Interactable, InteractEvent, DragEvent } from "@interactjs/types";

import copy from "fast-copy";

//@ts-ignore
import handle from "../../../assets/four-way-arrow.png";

export default class OrderingQuestionComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.studentResponse =  {
      ordering: {
        ordered_choice_ids: copy(this.props.studentResponse?.ordering?.ordered_choice_ids) || new Array((this.props.item.responseDeclaration.choices || []).length),
      },
    };
  }

  shouldComponentUpdate() {
    return false;
  }

  responseCollector() {
    let choices = this.props.item.responseDeclaration.choices;

    if (choices) {
      let sortedChoices = choices
        .sort((a, b) => a.ordinal - b.ordinal);

      return (
        <div class="response-collector">
          <div className="ordering-match-container">
            {sortedChoices.map((choice, index) => {
              return (
                <div className="ordering-row">
                  <div className="ordering-target" data-index={index}></div>
                </div>
              );
            })}
          </div>
          <div className="ordering-choice-container">
            <button
              class="ordering-clear"
              onClick={() => {
                this.clearResponse();
              }}
            >
              Clear Response
            </button>
            {sortedChoices.map((choice) => {
              return (
                <div
                  key={choice.id}
                  id={`ordering-choice-${choice.id}`}
                  data-id={choice.id}
                  className="ordering-choice"
                >
                  <img src={handle} class="ordering-choice-handle" />
                  <div
                    class="ordering-choice-content"
                    dangerouslySetInnerHTML={{ __html: choice.body }}
                  ></div>
                </div>
              );
            }) || []}
          </div>
        </div>
      );
    }
  }

  componentDidMount() {
    this.props.didRender();
    var _choiceContainer;
    if (this.base instanceof HTMLElement) {
      _choiceContainer = this.base.querySelector(".ordering-choice-container");
      if (_choiceContainer instanceof HTMLElement) {
        var choiceContainer = _choiceContainer;
      } else {
        throw "Failed to find .ordering-choice-container";
      }
    } else {
      throw "expected base to be an html element";
    }
    this.base.querySelectorAll(".ordering-target").forEach(
      /**
       * @param {HTMLDivElement} orderingTarget
       */
      (orderingTarget, i) => {
        orderingTarget.innerHTML = "";
        let res = this.studentResponse.ordering?.ordered_choice_ids[i];
        if (res !== null || res !== undefined) {
          let elem = choiceContainer.querySelector(
            `#ordering-choice-${res}`
          );
          if (elem) {
            orderingTarget.appendChild(elem);
          }
        }
      }
    );

    /**
     * @type Interactable[]
     */
    let interactions = [];
    var n = interactions.push(interact(".ordering-choice"));
    interact.dynamicDrop(true)
    /**
     * @type HTMLElement | null
     */
    var container = null;
    if(this.props.dragScrollContainer) {
      container = document.querySelector(this.props.dragScrollContainer)
    }

    interactions[n - 1].draggable({
      // enable inertial throwing
      inertia: true,
      // enable autoScroll
      autoScroll: {
        container: container || undefined,
        enabled: true
      },
      max: 1,

      listeners: {
        /**
         * 
         * @param {InteractEvent<DragEvent>} event 
         */
        start: function (event) {
          event.target.style.opacity = "0.75";
        },
        // call this function on every dragmove event
        move: dragMoveListener(container || undefined),

        // call this function on every dragend event
        end(event) {
          event.target.style.opacity = "1";
          if (!event.dropzone) {
            event.target.style.transform = "";
            event.target.removeAttribute("data-x");
            event.target.removeAttribute("data-y");
            event.target.removeAttribute("data-scroll-top");
          }
        },
      },
    });

    n = interactions.push(interact(".ordering-target"));
    interactions[n - 1].dropzone({
      // only accept elements ordering this CSS selector
      accept: ".ordering-choice",
      // listen for drop related events:
      ondropactivate: function (event) {
        // add active dropzone feedback
        event.target.classList.add("drop-active");
      },
      ondragenter: function (event) {
        event.target.classList.add("ordering-drag-over");
        event.relatedTarget.classList.add("can-drop");
      },
      ondragleave: function (event) {
        event.target.classList.remove("ordering-drag-over");
        event.relatedTarget.classList.remove("can-drop");
      },
      /**
       *
       * @param {InteractEvent} event
       */
      ondrop: (event) => {
        if (!(event.relatedTarget instanceof HTMLElement)) {
          return;
        }
        let existingGap = event.relatedTarget.closest(".ordering");
        if (existingGap instanceof HTMLElement) {
          this.removeChoice(Number(event.relatedTarget.dataset.id));
        }

        let existingChoiceElem =
          event.target.querySelector(".ordering-choice");
        event.target.classList.remove("ordering-drag-over");
        event.relatedTarget.classList.remove("can-drop");
        event.target.appendChild(event.relatedTarget);
        event.relatedTarget.style.transform = "";
        event.relatedTarget.removeAttribute("data-x");
        event.relatedTarget.removeAttribute("data-y");
        event.relatedTarget.removeAttribute("data-scroll-top");

        if (existingChoiceElem instanceof HTMLElement) {
          choiceContainer.appendChild(existingChoiceElem);
          this.props.item.attributes.response_declaration.choices
            ?.sort((a, b) => a.ordinal - b.ordinal)
            .forEach((choice) => {
              let choiceElem = choiceContainer.querySelector(
                `#ordering-choice-${choice.id}`
              );
              if (choiceElem) {
                choiceContainer.appendChild(choiceElem);
              }
            });

          this.removeChoice(Number(existingChoiceElem.dataset.id));
        }

        this.addChoice(
          Number(event.relatedTarget.dataset.id),
          Number(event.target.dataset.index)
        );
      },
      ondropdeactivate: function (event) {
        event.target.classList.remove("ordering-drag-over");
      },
    });

    n = interactions.push(interact(choiceContainer));
    interactions[n - 1].dropzone({
      accept: ".ordering-choice",
      ondrop: (event) => {
        event.target.appendChild(event.relatedTarget);
        event.relatedTarget.style.transform = "";
        event.relatedTarget.removeAttribute("data-x");
        event.relatedTarget.removeAttribute("data-y");
        this.props.item.attributes.response_declaration.choices
          ?.sort((a, b) => a.ordinal - b.ordinal)
          .forEach((choice) => {
            let choiceElem = choiceContainer.querySelector(
              `#ordering-choice-${choice.id}`
            );
            if (choiceElem) {
              choiceContainer.appendChild(choiceElem);
            }
          });

        this.removeChoice(Number(event.relatedTarget.dataset.id));
      },
    });

    this.interactions = interactions;
  }

  componentWillUnmount() {
    if (this.interactions) {
      this.interactions.forEach((i) => {
        i.unset();
      });
    }
  }

  render() {
    return this.wrapper(this.body(), this.responseCollector());
  }

  /**
   * @param {number} choiceId
   * @param {number} index 
   */
  addChoice(choiceId, index) {
    this.studentResponse = {
      ordering: {
        ordered_choice_ids: (this.studentResponse.ordering?.ordered_choice_ids || []).map(
          (id) => {
            if(id == choiceId) {
              return null; 
            } else {
              return id;
            }
          }
        ),
      },
    };

    if(this.studentResponse.ordering?.ordered_choice_ids) {
      this.studentResponse.ordering.ordered_choice_ids[index] = choiceId;
    }
    this.props.responseDidChange(this.studentResponse);
  }

  /**
   *
   * @param {number} choiceId
   */
  removeChoice(choiceId) {
    if(!this.studentResponse.ordering) {
      return;
    }

    this.studentResponse = {
      ordering: {
        ordered_choice_ids: this.studentResponse.ordering.ordered_choice_ids.map(
          (id) => {
            if(id == choiceId) {
              return null; 
            } else {
              return id;
            }
          }
        ),
      },
    };
    this.props.responseDidChange(this.studentResponse);
  }

  clearResponse() {
    if (!this.base || !(this.base instanceof HTMLElement)) {
      return;
    }
    if (
      !this.studentResponse.ordering ||
      this.studentResponse.ordering.ordered_choice_ids.length == 0
    ) {
      return;
    }
    let base = this.base;
    let container = base.querySelector(".ordering-choice-container");

    this.props.item.attributes.response_declaration.choices
      ?.sort((a, b) => a.ordinal - b.ordinal)
      .forEach((choice) => {
        let choiceElem = base.querySelector(`#ordering-choice-${choice.id}`);
        if (choiceElem) {
          container?.appendChild(choiceElem);
        }
      });

    this.studentResponse = {
      ordering: {
        ordered_choice_ids: [],
      },
    };

    this.props.responseDidChange(this.studentResponse);
  }
}


/**
 *
 * @param {HTMLElement | undefined} container 
 */
 function dragMoveListener(container) {
  /**
   * 
   * @param {InteractEvent} event 
   */
  return function(event){
    var target = event.target;

    var offset = 0;
    if(container) {
      var originalScrollTop = target.getAttribute("data-scroll-top")
      if(!originalScrollTop) {
        originalScrollTop = String(container.scrollTop);
        target.setAttribute("data-scroll-top", originalScrollTop)
      }

      var currentScrollTop = container.scrollTop;
      offset = parseFloat(originalScrollTop) - currentScrollTop;
    }

    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute("data-x") + "") || 0) + event.dx;
    var y = (parseFloat(target.getAttribute("data-y") + "") || 0 ) + event.dy ;

    // translate the element
    target.style.transform = "translate(" + x + "px, " + (y - offset) + "px)";

    // update the posiion attributes
    target.setAttribute("data-x", String(x));
    target.setAttribute("data-y", String(y));
  };
};