import Question from "./question.js";
import GapMatchQuestionComponent from "../components/questions/gap-match-question.jsx";
import GapMatchReportComponent from "../components/reports/gap-match-report.jsx";
import GapMatchPreviewComponent from "../components/previews/gap-match-preview.jsx";

export default class GapMatchQuestion extends Question {
  component(mode) {
    switch(mode) {
      case "student-facing-report":
        return GapMatchReportComponent;
      case "preview":
        return GapMatchPreviewComponent;
      default:
        return GapMatchQuestionComponent;
    }
  }

  /**
   * @param {HTMLElement} elem
   */
  processPromptMarkupForTts(elem) {
    elem.querySelectorAll(".gap-match").forEach(
      /**
       * 
       * @param {HTMLElement} element 
       */
      (element) => {
        let breakTag = document.createElement("break");
        element.replaceWith(breakTag)
      });

    return elem
  }
}
