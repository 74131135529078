import { icon, library} from '@fortawesome/fontawesome-svg-core'
import { faMinus, faCheck, faTimes, faPlay, faPause, faEye, faBackward, faEllipsisV, faVolumeUp, faMarker, faTrash, faHighlighter, faFilePen, faXmark, faCalculator, faBackspace, faWarning, faSquareRootAlt, faFile, faFileVideo} from '@fortawesome/free-solid-svg-icons'
library.add(faMinus, faCheck, faTimes, faPause, faPlay, faEye, faBackward, faEllipsisV, faVolumeUp, faMarker, faTrash, faHighlighter, faFilePen, faXmark, faCalculator, faBackspace, faWarning, faSquareRootAlt, faFile, faFileVideo)

function makeIcon(def) {
  return icon({prefix: def.prefix, iconName: def.iconName}).html.join()
}

export const minus = makeIcon(faMinus);
export const check = makeIcon(faCheck);
export const times = makeIcon(faTimes);
export const pause = makeIcon(faPause);
export const play = makeIcon(faPlay);
export const eye = makeIcon(faEye);
export const backward = makeIcon(faBackward);
export const ellipsisVertical = makeIcon(faEllipsisV);
export const volume = makeIcon(faVolumeUp);
export const marker = makeIcon(faHighlighter);
export const trash = makeIcon(faTrash);
export const note = makeIcon(faFilePen);
export const xMark = makeIcon(faXmark);
export const calculator = makeIcon(faCalculator);
export const backspace = makeIcon(faBackspace);
export const warning = makeIcon(faWarning);
export const squareRoot = makeIcon(faSquareRootAlt);
export const file = makeIcon(faFile);
export const fileVideo = makeIcon(faFileVideo);