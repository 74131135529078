import Question from "./question.js"
import InlineChoiceQuestionComponent from "../components/questions/inline-choice-question.jsx"
import InlineChoiceReportComponent from "../components/reports/inline-choice-report.jsx";
import InlineChoicePreviewComponent from "../components/previews/inline-choice-preview.jsx";

class InlineChoiceQuestion extends Question {
  constructor(item) {
    super(item);
    this.bodyWithSubstitutions = this.bodyWithFilledOutSelects();
  }

  component(mode) {
    switch(mode) {
      case "student-facing-report":
        return InlineChoiceReportComponent;
      case "preview":
        return InlineChoicePreviewComponent;
      default:
        return InlineChoiceQuestionComponent;
    }
  }

  /**
   * @returns string
   */
  bodyWithFilledOutSelects() {
    let parser = new DOMParser();
    let dom = parser.parseFromString(this.body, "text/html");
    dom.querySelectorAll(".inline-choice").forEach((inlineChoice) => {
      if(!(inlineChoice instanceof HTMLElement)) { return; };

      let gapId = Number(inlineChoice.dataset.gapIdentifier);
      if(Number.isNaN(gapId)) { return; }

      (this.responseDeclaration.choices || []).filter((choice) => {
        return choice.gap_id === gapId;
      }).sort((choiceA, choiceB) => {
        return choiceA.ordinal - choiceB.ordinal
      }).forEach((choice) => {
        let choiceElement = document.createElement("option");
        choiceElement.value = String(choice.id);
        choiceElement.innerText = choice.body;
        inlineChoice.appendChild(choiceElement);
      });
    });
    return dom.body.innerHTML;
  }

  /**
   * @param {HTMLElement} elem
   */
  processPromptMarkupForTts(elem) {
    elem.querySelectorAll(".inline-choice").forEach(
      /**
       * 
       * @param {HTMLSelectElement} element 
       */
      (element) => {
        let breakTag = document.createElement("break");
        element.parentElement?.insertBefore(breakTag, element);
        let selectSSML = "";
        Array.from(element.options).forEach((option) => {
          selectSSML += option.textContent;
          selectSSML += "<break></break>"
        })
        let span = document.createElement("span");
        element.replaceWith(span);
        span.innerHTML = selectSSML;

        
        let start_mark = document.createElement("mark");
        start_mark.setAttribute("value", "highlight_ignore_start");
        let end_mark = document.createElement("mark");
        end_mark.setAttribute("value", "highlight_ignore_end");

        span.parentElement?.insertBefore(start_mark, span);
        span.parentElement?.insertBefore(start_mark, span.nextSibling);
      })

    return elem
  }
}

export default InlineChoiceQuestion;