import BaseComponent from "../base-component.js";
import { h } from "preact";

export default class MatchingPreviewComponent extends BaseComponent {
    responseCollector() {
        let choices = this.props.item.responseDeclaration.choices;
        let correctResponse = this.props.item.correctResponse;

        if (choices) {
            let mathingTargets = choices
                .filter((c) => c.column == 0)
                .sort((a, b) => a.ordinal - b.ordinal);
            let matchingChoices = choices
                .filter((c) => c.column == 1)
                .sort((a, b) => a.ordinal - b.ordinal);

            let choicesById = mathingTargets.reduce((acc, choice) => {
                acc[choice.id] = choice;
                return acc;
            }, {});

            /**
             * @type {Object.<number, number>}
             */
            let matchIdByChoiceId = (correctResponse.definition || []).reduce((acc, def) => {
                acc[def.matches[0]] = def.matches[1]
                return acc;
            }, {}); 

            let choiceIdByMatchId = (correctResponse.definition || []).reduce((acc, def) => {
                acc[def.matches[1]] = def.matches[0]
                return acc;
            }, {});

            let unusedChoices = mathingTargets.filter((choice) => !matchIdByChoiceId[choice.id]);

            return (
                <div class="response-collector">
                    <div className="matching-match-container">
                        {matchingChoices.map((choice) => {

                            let matchedChoice = choicesById[choiceIdByMatchId[choice.id]];

                            return (
                                <div className="matching-row">
                                    <div className="matching-target" data-id={choice.id}>
                                        {matchedChoice ? this.renderChoice(matchedChoice) : null}
                                    </div>
                                    <div className="matching-arrows">&harr;</div>
                                    <div
                                        key={choice.id}
                                        id={`matching-match-${choice.id}`}
                                        data-id={choice.id}
                                        className="matching-match"
                                    >
                                        <div
                                            class="matching-match-content"
                                            dangerouslySetInnerHTML={{ __html: choice.body }}
                                        ></div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="matching-choice-container">
                        {unusedChoices?.map((choice) => {
                            return (
                                this.renderChoice(choice)
                            );
                        }) || []}
                    </div>
                </div>
            );
        }
    }


    renderChoice(choice) {
        return <div
            key={choice.id}
            id={`matching-choice-${choice.id}`}
            data-id={choice.id}
            className="matching-choice"
        >
            <div
                class="matching-choice-content"
                dangerouslySetInnerHTML={{ __html: choice.body }}
            ></div>
        </div>

    }

    render() {
        return this.wrapper(this.body(), this.responseCollector());
    }
}