import { h, render} from 'preact';

import requestStore from "./request-store.js"
import Item from "./models/item.js"

import Tools from "./tools.jsx"

import "./styles/reset.css"
import "./styles/base.css"
import "./styles/gridded.css"
import "./styles/matching.css"
import "./styles/mc.css"
import "./styles/ordering.css"
import "./styles/gap-match.css"
import "./styles/loading-state.css"
import "./styles/essay.css"
import "./styles/fill-in-the-blank.css"
import "./styles/color-picker.css"
import "./styles/color-picker.css"
import "./styles/popup.css"
import "./styles/tts-tool.css"
import "./styles/highlighter-tool.css"
import "./styles/notepad-tool.css";
import "./styles/calculator-tool.css";
import "./styles/embed-tool.css";
import "./styles/floating-window.css"

import { fromJsonApi } from "./models.js";
import HighlightEditor from './highlight-editor.js';

/**
 * @typedef {import("./shared-types").LegacyPerformanceEventResponse} LegacyPerformanceEventResponse
 */

/**
 * @typedef {import("./shared-types").ItemJSON} ItemJSON
 */

/**
 * @typedef {import("./shared-types").TeacherResponse} TeacherResponseObject
 */

/**
 * @typedef {import("./shared-types").ScoringData} ScoringDataObject
 */

class MMItemPlayer {
  /**
   * @param {{
    * source: string,
    * data?: ItemJSON,
    * element: string,
    * dragScrollContainer?: string,
    * didRender: function,
    * teacherResponseDidFinishEdit: (teacherResponse: TeacherResponseObject) => Promise | undefined,
    * teacherResponseDidChange: function | undefined,
    * responseDidChange:  function | undefined,
    * legacyPerformanceEventResponse: LegacyPerformanceEventResponse[] | null,
    * studentResponse: import("./shared-types").StudentResponse,
    * teacherResponse: TeacherResponseObject | undefined,
    * scoringData: ScoringDataObject | undefined,
    * readOnly: boolean,
    * useRequestStore: boolean,
    * id?: string,
    * type?: string
    * mode?: 'teacher-facing' | 'student-facing' | 'preview' | 'student-facing-report'
    * hidePoints: boolean,
    * hideCorrectAnswers: boolean,
    * bodyDiff?: any[],
   * }} options
   * @param {fetch|undefined} fetchFunc
   *
   */
  constructor(options, fetchFunc=fetch) {
    this.source = options.data ? null : options.source || this.buildUrl( options.id, options.type );
    this.elementString = options.element;
    /**
     * @type HTMLElement | null
     */
    let _element = document.querySelector(this.elementString)
    if(_element) {
      this.element = _element;
    }

    if(!this.element) {
      throw "Element Not Found"
    }
    this.dragScrollContainer = options.dragScrollContainer;
    this.didRender = options.didRender || (() => {});
    this.fetch = fetchFunc;
    this.response = options.studentResponse;
    this.legacyPerformanceEventResponse = options.legacyPerformanceEventResponse;
    this.teacherResponse = options.teacherResponse;
    this.scoringData = options.scoringData;
    this.readOnly = options.readOnly || false;
    this.useRequestStore = options.useRequestStore || true;
    this.mode = options.mode || "student-facing"
    this.hidePoints = options.hidePoints || false;
    this.hideCorrectAnswers = options.hideCorrectAnswers || false;
    //@type {any}
    this.itemComponent = null;
    this.teacherResponseDidChange = options.teacherResponseDidChange || function() {};
    this.teacherResponseDidFinishEdit = options.teacherResponseDidFinishEdit || function() {};
    this.responseDidChange = options.responseDidChange || function() {};
    this.bodyDiff = options.bodyDiff
    if(options.data) {
      this.createItemAndRender(options.data);
    } else {
      this.renderLoadingState();
      this.fetchItem()
        .then((json) => {
          this.clear();
          this.createItemAndRender(json)
        });
    }
  }

  /**
   * @param {LegacyPerformanceEventResponse[]} r
   * @param {string} responseType
   * @returns import("./shared-types").StudentResponse | null
   */
  convertLegacyPerformanceEventResponse(r, responseType) {
    /**
     * @type import("./shared-types").StudentResponse | null
     */
    var studentResponse = null;
    if(r.length <= 0) {
      return null;
    }

    if(responseType == "drawing" && (r[0].image_data || r[0].image_url)) {
       studentResponse = {
        drawing: {
          image_data: r[0].image_data,
          image_url: r[0].image_url
        }
      };
    } else if(responseType == "fill-in-the-blank") {
      studentResponse = {
        fill_in_the_blank: {
          input_responses: r.map((d) => {
            if(d.input_id) {
              return {text: d.text, input_id: d.input_id};
            } else {
              throw("Missing input id");
            }
          })
        }
      };
    } else if(responseType == "essay" || responseType == "equation") {
      studentResponse = {
        essay: {
          response: r[0].text
        }
      };
    }

    return studentResponse;
  }

  /**
   * @param {boolean} tf
   */
  setReadOnly(tf) {
    this.readOnly = tf;
    this.clear();
    this.render();
  }

  /**
   * @param {string|undefined} id
   * @param {string|undefined} type
   * @returns String
   */
  buildUrl( id, type ) {
    if( id && type ) {
      if(type == "QuestionVersion") {
        return `/-/question_versions/${id}?include=reference_material`
      } else if( type == "Question" ) {
        return `/-/questions/${id}?include=reference_material`
      } else {
        throw `Unsupported Type #{type}`
      }
    } else {
      throw `Missing required options id, and type`
    }
  }

  /**
   * @returns Promise<ItemJSON>
   */
  fetchItem() {
    if(!this.source) { throw new Error("Missing source or type + id options") }
    if(this.useRequestStore) {
      let request = requestStore.findRequest(this.source)
      if(request) {
        return request;
      }
    }

    /**
     *  @type {(s: Response) => Promise<ItemJSON>}
     */
    let toJSON = (r) => {
      return r.json();
    }

    /**
     * @type Promise<ItemJSON>
     */
    let promise = this.fetch.call(window, this.source, {
      credentials: "include"
    }).then(toJSON);

    requestStore.registerRequest(this.source, promise)

    return promise;
  }

  /**
   * @param {ItemJSON} itemJSON
   */

  createItemAndRender(itemJSON) {
    this.item = this.createItem(itemJSON);
    this.render()
  }

  /**
   * @param {ItemJSON} itemJSON
   * @returns {Item}
   */
  createItem(itemJSON) {
    return fromJsonApi(itemJSON);
  }

  render() {
    if(!this.item) {return};

    this.itemComponent = this.item.component(this.mode);
    render(h(this.itemComponent, {
      item: this.item,
      didRender: this.didRender,
      readOnly: this.readOnly,
      studentResponse: this.legacyPerformanceEventResponse ? this.convertLegacyPerformanceEventResponse(this.legacyPerformanceEventResponse, this.item.data.attributes.response_type) : this.response,
      teacherResponseDidChange: this.teacherResponseDidChange,
      teacherResponseDidFinishEdit: this.teacherResponseDidFinishEdit,
      teacherResponse: this.teacherResponse,
      scoringData: this.scoringData,
      responseDidChange: this.responseDidChange,
      mode: this.mode,
      hidePoints: this.hidePoints,
      hideCorrectAnswers: this.hideCorrectAnswers,
      dragScrollContainer: this.dragScrollContainer,
      bodyDiff: this.bodyDiff
    }), this.element);
  }

  destroy() {
    render(null, this.element)
  }

  renderLoadingState() {
    var div = document.createElement("div");
    div.setAttribute("class", "item-player-loading");
    this.element.appendChild(div);
  }

  clear() {
    this.element.innerText = "";
  }

  static loadEssayEditor() {
    return import("../essay-editor/app.js")
  }
}

MMItemPlayer.Tools = Tools;
MMItemPlayer.HighlightEditor = HighlightEditor;

//@ts-ignore
window["MMItemPlayer"] = MMItemPlayer;
