import GapMatchComponent from "../gap-match-component.jsx";
import { h, render } from "preact";
import copy from "fast-copy";
//@ts-ignore
export default class GapMatchPreviewComponent extends GapMatchComponent {

  componentDidMount() {
    this.props.didRender();
    if (!(this.base instanceof HTMLElement)) {
      throw ("expected base to be an html element");
    }

    this.base.querySelectorAll(".gap-match").forEach(
      /**
       * @param {HTMLSpanElement} gapMatchElement
       */
      (gapMatchElement) => {
        if (gapMatchElement instanceof HTMLSpanElement) {

          var container = document.createElement('SPAN');
          gapMatchElement.replaceWith(container);

          let correctResponseGapMatches = this.correctResponseGapMatches(gapMatchElement.dataset.gapIdentifier || "", true);
          if (correctResponseGapMatches) {
            if (correctResponseGapMatches.length == 1) {
              render(this.gapAnswer(correctResponseGapMatches), container);
            }
            else {
              render(this.gapPopup(correctResponseGapMatches), container);
            }
          }
          else {
            render(this.noAnswer(), container);
          }
        }
      });
  }

  render() {
    return this.wrapper(this.body());
  }
}
