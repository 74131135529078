import BaseComponent from "./base-component.js";
import { h } from "preact";
import copy from "fast-copy";
//@ts-ignore
export default class GapMatchComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.hidePoints = props.hidePoints;
    this.hideCorrectAnswers = props.hideCorrectAnswers;
    this.studentResponse = copy(this.props.studentResponse) || {
      gap_match: {
        gap_match_matches: [],
      },
    };
    this.correctResponse = copy(this.props.item.correctResponse) || {
      max_score: 0,
      scoring_mode: "",
      definition: [{
        gap_matches: [],
      }],
    };
    this.scoringData = copy(this.props.scoringData) || {
      max_score: 0,
      scoring_mode: "",
      definition: [{
        gap_matches: [],
      }],
    };
    this.gapPopupToggle = this.gapPopupToggle.bind(this);
  }

  shouldComponentUpdate() {
    return false;
  }

  gapMatch(gapId) {
    return this.studentResponse.gap_match?.gap_match_matches.find(
      (gapMatchMatch) => {
        return (
          String(gapMatchMatch.gap_id) == gapId
        );
      }
    );
  }

  correctResponseGapMatch(gapId, choiceId) {
    var gapMatch = null;

    this.correctResponse.definition?.forEach((definition) => {
      // @ts-ignore
      gapMatch = definition.gap_matches?.find(
        (gapMatch) => {
          return (
            gapMatch.gap_id == gapId
            && gapMatch.choice_id == choiceId
          );
        }
      );

    });

    return gapMatch;
  }

  /**
   * @param gapId {string}
   * @param onlyHighestScores {boolean}
   * @returns GapMatch[] | undefined
   */
  correctResponseGapMatches(gapId, onlyHighestScores) {
    let maxScore = this.correctResponse.max_score;
    let spreadPointsEvenly = 0;

    let scoringMode = this.correctResponse.scoring_mode;
    if (scoringMode === 'spread_points_evenly') {
      let gaps = this.props.item.responseDeclaration.gaps;
      if (maxScore && gaps) {
        spreadPointsEvenly = maxScore / gaps.length;
      }
    }

    var gapMatches = [];
    this.correctResponse.definition?.forEach((d) => {

      if (scoringMode === 'spread_points_evenly') {
        d.gap_matches?.forEach((gapMatch) => {
          gapMatch.score = spreadPointsEvenly;
        });
      }

      // @ts-ignore
      gapMatches = d.gap_matches?.filter(
        (gapMatch) => {
          return (
            String(gapMatch.gap_id) == gapId
          );
        }
      );

      if (onlyHighestScores && scoringMode === 'point_associations') {
        gapMatches = [];
        d.gap_matches?.forEach((gapMatch) => {
          if (String(gapMatch.gap_id) != gapId) {
            return;
          }

          if (gapMatches === undefined || gapMatches.length == 0) {
            gapMatches = [gapMatch];
          }
          else {
            if (gapMatch.score > gapMatches[0].score) {
              gapMatches = [gapMatch];
            }
            else if (gapMatch.score == gapMatches[0].score) {
              gapMatches.push(gapMatch);
            }
          }
        });
      }
    });

    return gapMatches;
  }

  /**
   * 
   * @param {string | number} id 
   * @param {string | number | null} gapId 
   */
  choice(id, gapId) {
    return this.props.item.attributes.response_declaration.choices?.find(function (choice) {
      return choice.id === id && ((!gapId || (choice.gap_id === undefined || choice.gap_id === null)) || choice.gap_id === Number(gapId));
    });
  }

  maxScore(correctResponseGapMatches) {
    let maxCorrectResponseGapMatch = correctResponseGapMatches?.reduce((previous, current) => {
      return previous == null || previous.score < current.score ? current : previous;
    });
    return maxCorrectResponseGapMatch?.score || 0;
  }

  gapAnswer(correctResponseGapMatches) {
    let choice = this.choice(correctResponseGapMatches[0].choice_id, correctResponseGapMatches[0].gap_id);
    return (
      <div className="static-choice">
        <div dangerouslySetInnerHTML={{ __html: choice?.body || '' }}></div>
        <table class="score">
          <tr>
            {this.glyph(correctResponseGapMatches, correctResponseGapMatches[0])}
            {this.score(correctResponseGapMatches[0].score)}
          </tr>
        </table>
      </div>
    );
  }

  gapResponse(choice, correctResponseGapMatches, studentResponseGapMatch) {
    return (
      <div className="static-choice">
        <div dangerouslySetInnerHTML={{ __html: choice?.body || '' }}></div>
        <table class="score">
          <tr>
            {this.glyph(correctResponseGapMatches, studentResponseGapMatch)}
            {this.score(studentResponseGapMatch?.score || 0)}
          </tr>
        </table>
      </div>
    );
  }

  gapPopupFind(element) {
    if (element.classList.contains('popup')) {
      return element;
    }
    else {
      return this.gapPopupFind(element.parentElement);
    }
  }

  gapPopupToggle(e) {
    e.preventDefault();

    let popup = this.gapPopupFind(e.srcElement);
    let content = popup.querySelector(
      '.popup-content'
    );

    if (content) {
      if (content.style.visibility === 'visible') {
        content.style.visibility = 'hidden';
      }
      else {
        content.style.visibility = 'visible';
      }
    }
  }

  gapPopup(correctResponseGapMatches) {
    return (
      <div class="popup static-choice" onClick={this.gapPopupToggle}>
        <div>Click to See Answers</div>
        <div style='text-align:center;'><i class='glyphicon glyphicon-eye-open far fa-eye'></i></div>
        <div class="popup-content">
          <table>
            {correctResponseGapMatches.map((correctResponseGapMatch) => {
              var choice = this.choice(correctResponseGapMatch.choice_id, correctResponseGapMatch.gap_id);
              return (
                <tr>
                  <td dangerouslySetInnerHTML={{ __html: choice?.body || '' }}></td>
                  {this.glyph(correctResponseGapMatches, correctResponseGapMatch)}
                  {this.score(correctResponseGapMatch.score)}
                </tr>
              )
            })}
          </table>
        </div>
      </div>
    );
  }

  noAnswer() {
    return (
      <div className="static-choice">
        No correct answer
      </div>
    );
  }

  glyph(correctResponseGapMatches, studentResponseGapMatch) {
    if (correctResponseGapMatches && !this.hideCorrectAnswers) {
      let classList = 'glyphicon fas fa-times glyphicon-remove text-danger';

      if (studentResponseGapMatch) {
        let maxScore = this.maxScore(correctResponseGapMatches);

        if (studentResponseGapMatch.score == maxScore) {
          classList = 'glyphicon fas fa-check glyphicon-ok text-success';
        }
        else {
          classList = 'glyphicon fas fa-minus glyphicon-minus text-warning';
        }
      }
      return (
        <td><i class={classList}></i></td>
      );
    }
    else {
      return ('');
    }
  }

  score(score) {
    if (!this.hidePoints && this.correctResponse.scoring_mode !== 'all_or_nothing') {
      return (
        <td>
          <span class="larger_student_response_points">{this.round(score)}</span>
        </td>
      );
    }
    else {
      return ('');
    }
  }
}
