class RequestStore {
  constructor() {
    /**
     * @type {Object}
     */
    this._promises = {};
  }

  /**
   * @param {string} url
   * @param {Promise<import("./shared-types").ItemJSON>} promise
   */
  registerRequest(url, promise) {
    this._promises[url] = promise;
  }

  /**
   * @param {string} url
   * @returns {Promise<import("./shared-types").ItemJSON> | undefined}
   */
  findRequest(url) {
    return this._promises[url];
  }
}

export default new RequestStore();
