
import "@interactjs/auto-start";
import "@interactjs/auto-scroll";
import "@interactjs/actions/drag";
import "@interactjs/actions/drop";
import "@interactjs/actions/resize";
import "@interactjs/inertia";
import '@interactjs/modifiers';
import interact from "@interactjs/interact";
import { InteractEvent } from "@interactjs/core/InteractEvent";

export default interact;

/**
 * 
 * @param {Element} el 
 * @returns 
 */
function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    };
}

/**
 * 
 * @param {InteractEvent} evt 
 * @param {{x: number, y: number}} prevPosition 
 */
export function makeNewRestrictedPosition(evt, prevPosition) {
    let deltaX = evt.dx;
    let deltaY = evt.dy;
    if (evt.rect.top < 0 && evt.dy < 0) {
        deltaY = 0 - getOffset(evt.target).top 
    }

    if (evt.rect.left < 0 && evt.dx < 0) {
        deltaX = 0 - getOffset(evt.target).left 
    }

    if (evt.rect.right > window.innerWidth && evt.dx > 0) {
        deltaX = window.innerWidth - (getOffset(evt.target).left + evt.target.clientWidth);
    }

    return {
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY
    }
}